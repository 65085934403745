import Kichiri from "./KichiriService";
import UserService from "./UserService";

const CompanyService = {
	/**
	 * Fetches the companies info.
	 * @returns {Object}
	 */
	async get() {
		let userData = UserService.getUserData();
		try {
			let response = await Kichiri.company.get(
				{
					companyId: userData.companyId
				},
				{},
				userData.authToken
			);

			return response;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Updates a companies info.
	 * @param {String} name
	 * @param {String} email
	 * @param {String} phone
	 * @param {String} sms
	 * @param {String} notes
	 */
	async update({ name, email, phone, sms, notes }) {
		let userData = UserService.getUserData();

		try {
			await Kichiri.company.update(
				{
					companyId: userData.companyId,
					name,
					email,
					phone,
					sms,
					notes
				},
				{},
				userData.authToken
			);
		} catch (error) {
			console.log(error);
		}
	}
};

export default CompanyService;
