import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import { RRule, rrulestr } from "rrule";
import moment from "moment";

import { FREQUENCIES, RRULE_BYSETPOS, RRULE_END_TYPES, RRULE_MONTHS, RRULE_MONTH_WEEK, RRULE_MONTH_WEEK_DAY, WEEKDAYS } from "../../../constants/Schedules";

import TextFieldInput from "../text_field_input/TextFieldInput";
import Select from "../Select/Select";

import "./rrule.css";
import ScheduleService from "../../../services/ScheduleService";

const RRULE_MONTH_TYPE = {
	day: "day",
	week: "week"
};

class RRuleEditor extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			frequencies: Object.values(FREQUENCIES),
			frequency: FREQUENCIES.never,

			startDate: props.startDate,

			// Frequency: Daily
			days: 1,

			// Frequency: Weekly
			weeks: 1,
			selectedWeekdays: {},

			// Frequency: Months
			months: 1,
			monthType: RRULE_MONTH_TYPE.day,
			monthDay: { id: 1, name: 1 }, // Also used in Yearly
			monthWeek: RRULE_MONTH_WEEK.first,
			monthWeekDay: RRULE_MONTH_WEEK_DAY[0],

			// Frequency: Yearly
			month: RRULE_MONTHS[0],

			endType: RRULE_END_TYPES[0],
			endExecutions: 0,
			endDate: new Date()
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidUpdate(prevProps) {}

	resetComponent = async () => {};

	componentDidMount = () => {
		this.computeRRuleString();
	};

	computeRRuleString = async () => {
		// Grab the RRule from the props
		let { rrule } = this.props;

		if (!rrule || rrule.length === 0) {
			return;
		}

		// Use the rrule library to parse and interpret the rrule string
		let rruleObject = rrulestr(rrule);
		// Grab the original options, since this will be the main object we will be using to construct our component state from the RRule string
		let options = rruleObject.origOptions;
		// Get the frequency
		let frequency = options.freq;

		// For the DAILY frequency, all we need is the days
		if (frequency === RRule.DAILY) {
			await this.update({
				frequency: FREQUENCIES.daily,
				days: options.interval
			});
		}
		// If the frequency is WEEKLY
		else if (frequency === RRule.WEEKLY) {
			// Retreive the byweekday from the rrule object (eg. BYWEEKDAY=MO,TU,WE)
			// Tis is a list of RRule Weekday Objects in which is the following anatomy = [{ weekday: 0 }, { weekday: 1 } ... ]
			let byweekday = options.byweekday;
			// Create a state specific selectedWeekdays object
			let selectedWeekdays = {};

			// Go through each item in the RRule object byweekday array, map it to a day and then set the key in the selectedWeekday object
			// eg. [{ weekday: 0 }, { weekday: 2 }]  --->  { Monday: true, Wednesday: true }
			for (let item of byweekday) {
				selectedWeekdays[WEEKDAYS[item.weekday]] = true;
			}

			await this.update({
				frequency: FREQUENCIES.weekly,
				weeks: options.interval,
				selectedWeekdays
			});
		} else if (frequency === RRule.MONTHLY) {
			// Retrieve the by month day from the RRule object eg. BYMONTHDAY=3
			let bymonthday = options.bymonthday;
			// Determine if the by month day attribute exists
			let doesByMonthDayExist = typeof bymonthday !== "undefined";

			// If the BYMONTHDAY attribute exists, then this is a monthly frequency RRule with specified days
			let monthType = doesByMonthDayExist ? RRULE_MONTH_TYPE.day : RRULE_MONTH_TYPE.week;

			if (monthType === RRULE_MONTH_TYPE.day) {
				// XXX - Special case for quarterly
				if (options.interval === 3 && bymonthday === 1) {
					await this.update({
						frequency: FREQUENCIES.quarterly
					});
				}
				// Otherwise regular case for monthly RRules
				else {
					await this.update({
						frequency: FREQUENCIES.monthly,
						months: options.interval,

						monthType,
						monthDay: { id: bymonthday, name: bymonthday }
					});
				}
			} else if (monthType === RRULE_MONTH_TYPE.week) {
				// Using the value of the BYSETPOS we can know which we can know which of the FIRST, SECOND, THIRD, FOURTH, LAST positions need to be set for this RRule
				let monthWeek = RRULE_BYSETPOS[options.bysetpos.toString()];

				// Gross starts here
				// Using the embedded byweekday array in the RRule Object, we can determine the weekdays that this monthly frequency rule applies for
				// eg. [0,2,4] ---> [MO,WE,FR]
				let value = rruleObject.options.byweekday.map(day => {
					return WEEKDAYS[day].substring(0, 2).toUpperCase();
				});

				// Generate the match string for the RRule
				// eg. [MO,WE,FR]  --->  "MO,WE,FR"
				let rruleValue = value.join(",");
				let rruleSelected = null;

				// Iterate over all the Month Week Day possible rules and match the rrule BYWEEKDAY code to determine the right option for the UI
				// eg. "SA,SU"  --->  { id: "weekendDay", name: "Weekend Day", rrule: "SA,SU" }
				for (let rule of RRULE_MONTH_WEEK_DAY) {
					if (rule.rrule === rruleValue) {
						rruleSelected = rule;
						break;
					}
				}
				// Gross ends here

				await this.update({
					frequency: FREQUENCIES.monthly,
					months: options.interval,

					monthType,

					monthWeek,
					monthWeekDay: rruleSelected
				});
			}
		} else if (frequency === RRule.YEARLY) {
			// Retrieve the by month day from the RRule object eg. BYMONTHDAY=3
			let bymonthday = options.bymonthday;
			let bymonth = options.bymonth;

			await this.update({
				frequency: FREQUENCIES.yearly,
				month: RRULE_MONTHS[bymonth - 1],
				monthDay: { id: bymonthday, name: bymonthday }
			});
		}

		// TODO Add end date code mapping here
	};

	generateRRule = () => {
		let {
			frequency,

			startDate,

			days,

			weeks,
			selectedWeekdays,

			months,
			monthType,
			monthDay,
			monthWeek,
			monthWeekDay,

			month,

			endType,
			endExecutions,
			endDate
		} = this.state;

		let startDateInUTC = ScheduleService.convertDateToRRuleFormat({ date: startDate });

		let rrule = `DTSTART:${startDateInUTC}\nRRULE:`;

		if (frequency.id === FREQUENCIES.daily.id) {
			rrule = `${rrule}FREQ=DAILY;INTERVAL=${days}`;
		} else if (frequency.id === FREQUENCIES.weekly.id) {
			rrule = `${rrule}FREQ=WEEKLY;INTERVAL=${weeks}`;

			if (Object.keys(selectedWeekdays).length > 0) {
				let weekDays = Object.keys(selectedWeekdays).map(selectedWeekday => {
					return selectedWeekday.substring(0, 2).toUpperCase();
				});

				let weekDayRRule = weekDays.join(",");

				rrule = `${rrule};BYDAY=${weekDayRRule}`;
			}
		} else if (frequency.id === FREQUENCIES.monthly.id) {
			rrule = `${rrule}FREQ=MONTHLY;INTERVAL=${months}`;

			if (monthType === RRULE_MONTH_TYPE.day) {
				rrule = `${rrule};BYMONTHDAY=${monthDay.id}`;
			} else if (monthType === RRULE_MONTH_TYPE.week) {
				let bySetPos = monthWeek.rrule;
				let byDay = monthWeekDay.rrule;

				rrule = `${rrule};${bySetPos};BYDAY=${byDay}`;
			}
		} else if (frequency.id === FREQUENCIES.quarterly.id) {
			let startOfThisYear = moment().startOf("year");
			let startDateInUTC = ScheduleService.convertDateToRRuleFormat({ date: startOfThisYear });

			rrule = `DTSTART:${startDateInUTC}\nRRULE:FREQ=MONTHLY;INTERVAL=3;BYMONTHDAY=1`;
		} else if (frequency.id === FREQUENCIES.yearly.id) {
			rrule = `${rrule}FREQ=YEARLY;BYMONTH=${month.id};BYMONTHDAY=${monthDay.id}`;
		}

		// End Type is "After"
		if (endType.id === RRULE_END_TYPES[1].id) {
			rrule = `${rrule};COUNT=${endExecutions}`;
		}
		// End type is "On Date"
		else if (endType.id === RRULE_END_TYPES[2].id) {
			let endDateInUTC = ScheduleService.convertDateToRRuleFormat({ date: endDate });

			rrule = `${rrule};UNTIL=${endDateInUTC}`;
		}

		// If the frequency defined is "Never", then set the rrule to an empty string
		if (frequency.id === FREQUENCIES.never.id) {
			rrule = "";
		}

		if (this.props.onChange) {
			this.props.onChange({ rrule });
		}
	};

	onInputChange = async event => {
		await this.update({ [event.target.name]: event.target.value });

		this.generateRRule();
	};

	onRepeatChange = async frequency => {
		await this.update({
			frequency
		});

		this.generateRRule();
	};

	onWeekdaySelected = async ({ weekday }) => {
		let { selectedWeekdays } = this.state;

		if (typeof selectedWeekdays[weekday] !== "undefined") {
			delete selectedWeekdays[weekday];
		} else {
			selectedWeekdays[weekday] = true;
		}

		await this.update({
			selectedWeekdays
		});

		this.forceUpdate();

		this.generateRRule();
	};

	renderRepeat() {
		let { frequency } = this.state;

		return (
			<div className="rrule__frequency-select">
				<div className="rrule__frequency-select__item">Repeat this engagement</div>
				<Select
					onSearch={({ searchTerm }) => ScheduleService.searchRRuleFrequencies({ searchTerm })}
					onSelect={this.onRepeatChange}
					selectedName={frequency.name}
					showAll
				/>
			</div>
		);
	}

	renderDaily() {
		let { days } = this.state;
		return (
			<div className="rrule__frequency">
				<div className="rrule__frequency__row">
					<div className="rrule__frequency__row__item">every</div>
					<TextFieldInput name={"days"} onChange={this.onInputChange} value={days} type={"number"} />
					<div className="rrule__frequency__row__item">day</div>
				</div>
			</div>
		);
	}

	renderWeekly() {
		let { weeks } = this.state;
		return (
			<div className="rrule__frequency">
				<div className="rrule__frequency__row">
					<div className="rrule__frequency__row__item">every</div>
					<TextFieldInput name={"weeks"} onChange={this.onInputChange} value={weeks} type={"number"} />
					<div className="rrule__frequency__row__item">week</div>
				</div>
				{this.renderWeekDays()}
			</div>
		);
	}

	renderWeekDays() {
		let { selectedWeekdays } = this.state;

		return (
			<>
				{/* <div className="client-filter-modal__general__label">Year Ends</div> */}
				<div className="pill-container">
					{WEEKDAYS.map(weekday => {
						return (
							<div className={`pill ${selectedWeekdays[weekday] ? "pill--active" : ""} `} onClick={() => this.onWeekdaySelected({ weekday })}>
								{weekday}
							</div>
						);
					})}
				</div>
			</>
		);
	}

	onMonthDayChange = async monthDay => {
		await this.update({ monthDay });
		this.generateRRule();
	};

	onMonthWeekChange = async monthWeek => {
		await this.update({ monthWeek });
		this.generateRRule();
	};

	onMonthWeekDayChange = async monthWeekDay => {
		await this.update({ monthWeekDay });
		this.generateRRule();
	};

	onSelectMonthType = async ({ type }) => {
		await this.update({ monthType: type });
		this.generateRRule();
	};

	onMonthChange = async month => {
		await this.update({ month });
		this.generateRRule();
	};

	renderMonthly() {
		let { months, monthType, monthDay, monthWeek, monthWeekDay } = this.state;

		return (
			<div className="rrule__frequency">
				<div className="rrule__frequency__row">
					<div className="rrule__frequency__row__item">every</div>
					<TextFieldInput name={"months"} onChange={this.onInputChange} value={months} type={"number"} />
					<div className="rrule__frequency__row__item">month</div>
				</div>

				<div className="rrule__frequency__row">
					<div
						className={`rrule__frequency__row__select ${monthType === RRULE_MONTH_TYPE.day ? "rrule__frequency__row__select--active" : ""}`}
						onClick={() => this.onSelectMonthType({ type: RRULE_MONTH_TYPE.day })}
					/>
					<div className="rrule__frequency__row__item">on day</div>
					<Select
						onSearch={({ searchTerm }) => ScheduleService.searchDays({ searchTerm })}
						onSelect={this.onMonthDayChange}
						selectedName={monthDay.name}
						showAll
					/>
				</div>

				<div className="rrule__frequency__row">
					<div
						className={`rrule__frequency__row__select ${monthType === RRULE_MONTH_TYPE.week ? "rrule__frequency__row__select--active" : ""}`}
						onClick={() => this.onSelectMonthType({ type: RRULE_MONTH_TYPE.week })}
					/>
					<div className="rrule__frequency__row__item">on the</div>
					<Select
						onSearch={({ searchTerm }) => ScheduleService.searchMonthWeeks({ searchTerm })}
						onSelect={this.onMonthWeekChange}
						selectedName={monthWeek.name}
						showAll
					/>
					<Select onSearch={({ searchTerm }) => RRULE_MONTH_WEEK_DAY} onSelect={this.onMonthWeekDayChange} selectedName={monthWeekDay.name} showAll />
				</div>
			</div>
		);
	}

	renderQuarterly() {
		return (
			<div className="rrule__frequency">
				<div className="rrule__frequency__row">
					<div className="rrule__frequency__row__item">On Jan 1st, April 1st, July 1st, and October 1st</div>
				</div>
			</div>
		);
	}

	renderYearly() {
		let { month, monthDay } = this.state;

		return (
			<div className="rrule__frequency">
				<div className="rrule__frequency__row">
					<div className="rrule__frequency__row__item">on</div>
					<Select onSearch={({ searchTerm }) => RRULE_MONTHS} onSelect={this.onMonthChange} selectedName={month.name} showAll />
					<Select
						onSearch={({ searchTerm }) => ScheduleService.searchDays({ searchTerm })}
						onSelect={this.onMonthDayChange}
						selectedName={monthDay.name}
						showAll
					/>
				</div>
			</div>
		);
	}

	onEndTypeChange = async endType => {
		await this.update({ endType });
		this.generateRRule();
	};

	onEndDateChange = async endDate => {
		await this.update({ endDate });
		this.generateRRule();
	};

	renderEndDate() {
		let { endType, endExecutions, endDate } = this.state;

		let occurrenceLabel = endExecutions > 1 ? "occurrences" : "occurrence";

		return (
			<div className="rrule__end">
				<div className="rrule__end__item">and end</div>
				<Select onSearch={({ searchTerm }) => RRULE_END_TYPES} onSelect={this.onEndTypeChange} selectedName={endType.name} showAll />

				{/* End Type is "After" */}
				{endType.id === RRULE_END_TYPES[1].id && (
					<>
						<TextFieldInput name={"endExecutions"} onChange={this.onInputChange} value={endExecutions} type={"number"} />
						<div className="rrule__end__item">{occurrenceLabel}</div>
					</>
				)}

				{/* End Type is "On Date" */}
				{endType.id === RRULE_END_TYPES[2].id && (
					<div className="client-details-datepicker-field-container">
						<DatePicker
							className="client-details-datepicker"
							selected={endDate}
							onChange={this.onEndDateChange}
							timeFormat="HH:mm"
							timeIntervals={15}
							dateFormat="MMMM d, yyyy"
							timeCaption="time"
							autoComplete="off"
						/>
					</div>
				)}
			</div>
		);
	}

	isFrequencyNever = () => {
		let { frequency } = this.state;

		return frequency.id === FREQUENCIES.never.id;
	};

	render() {
		let { frequency } = this.state;

		return (
			<div className="rrule">
				{this.renderRepeat()}
				{frequency.id === FREQUENCIES.daily.id && this.renderDaily()}
				{frequency.id === FREQUENCIES.weekly.id && this.renderWeekly()}
				{frequency.id === FREQUENCIES.monthly.id && this.renderMonthly()}
				{frequency.id === FREQUENCIES.quarterly.id && this.renderQuarterly()}
				{frequency.id === FREQUENCIES.yearly.id && this.renderYearly()}
				{!this.isFrequencyNever() && this.renderEndDate()}
			</div>
		);
	}
}

export default RRuleEditor;
