import React from "react";
import CLIENTS from "../../../constants/Clients";
import ClientService from "../../../services/ClientService";
import * as Icon from "react-feather";
import moment from "moment";
import ContactsModal from "./ContactsModal";

import "./contacts.css";
import Loader from "../../common/Loader";
import ContactService from "../../../services/ContactService";
import WComponent from "../../common/WComponent";

class Contacts extends WComponent {
	constructor(props) {
		super(props);

		let { title } = this.props;

		this.state = {
			loading: false,

			contacts: [],
			clientId: 0,
			contactId: "new",

			title,
			search: "",
			desc: true,
			showContactModal: false
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	resetComponent = async () => {
		let { clientId } = this.props.match.params;

		await this.update({
			clientId
		});

		this.fetchContacts();
	};

	onNewContact = () => {
		this.update({
			contactId: "new",
			showContactModal: true
		});
	};

	onContactSelect = ({ contactId }) => {
		this.update({
			contactId: contactId,
			showContactModal: true
		});
	};

	fetchContacts = async () => {
		let { clientId } = this.state;

		this.update({
			loading: true
		});

		try {
			let response = await ContactService.getListOfContacts({ clientId });

			await this.update({
				contacts: response.data
			});
		} catch (error) {
			console.log(error);
		}

		this.update({
			loading: false
		});
	};

	sortBy = async ({ field }, { date = false }) => {
		let { contacts, desc } = this.state;

		await this.update({
			sortedField: field,
			desc: !desc
		});

		contacts = contacts.sort((c1, c2) => {
			let a = c1[field];
			let b = c2[field];

			if (date) {
				a = moment(a);
				b = moment(b);
			}

			if (a > b) {
				return desc ? -1 : 1;
			}

			if (a < b) {
				return desc ? 1 : -1;
			}

			return 0;
		});

		await this.update({
			contacts
		});
	};

	onSearch = event => {
		this.update({
			search: event.target.value
		});
	};

	onContactClose = async () => {
		await this.update({
			loading: true,
			contactId: null,
			showContactModal: false
		});

		await this.fetchContacts();

		await this.update({
			loading: false
		});
	};

	searchContacts = () => {
		let { search, contacts } = this.state;

		let searchTerm = search;
		return contacts.filter(contact => {
			return (
				contact.first_name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
				contact.last_name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
				contact.email.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
				contact.phone.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
				contact.dob.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
				contact.status.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
				contact.sin.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
				ClientService.concatSin({ sin: contact.sin })
					.toLowerCase()
					.indexOf(searchTerm.toLowerCase()) !== -1
			);
		});
	};

	renderSortIcon = ({ field }) => {
		let { sortedField, desc } = this.state;
		return sortedField === field && (desc ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	render() {
		let { showContactModal, loading, search, title, contactId, clientId } = this.state;

		let filteredContacts = this.searchContacts();

		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Contacts</h3>
						<h1 className="container-header-title__name">{title}</h1>
					</div>

					<div className="container-header-options">
						<button className="container-add" onClick={this.onNewContact} type="button">
							<Icon.Plus size={18} />
						</button>
						<input
							className="container-search"
							type="search"
							name="search"
							placeholder="Search..."
							value={search}
							autoComplete="off"
							onChange={this.onSearch}
						/>
					</div>
				</div>

				{loading && (
					<div className="container-loader">
						<Loader />
					</div>
				)}

				{filteredContacts.length === 0 && !loading && (
					<div className="container-empty">
						<div>
							<Icon.Frown size={64} />
						</div>
						<div>... no contacts ...</div>
					</div>
				)}

				{filteredContacts.length > 0 && !loading && (
					<div className="list-container">
						<div className="list-header list-item">
							<div onClick={() => this.sortBy({ field: CLIENTS.contactOrderQueries.firstName }, {})} className="list-column">
								First Name {this.renderSortIcon({ field: CLIENTS.contactOrderQueries.firstName })}
							</div>
							<div onClick={() => this.sortBy({ field: CLIENTS.contactOrderQueries.lastName }, {})} className="list-column">
								Last Name {this.renderSortIcon({ field: CLIENTS.contactOrderQueries.lastName })}
							</div>
							<div onClick={() => this.sortBy({ field: CLIENTS.contactOrderQueries.email }, {})} className="list-column">
								Email {this.renderSortIcon({ field: CLIENTS.contactOrderQueries.email })}
							</div>
							<div onClick={() => this.sortBy({ field: CLIENTS.contactOrderQueries.phone }, {})} className="list-column">
								Phone {this.renderSortIcon({ field: CLIENTS.contactOrderQueries.phone })}
							</div>
							<div onClick={() => this.sortBy({ field: CLIENTS.contactOrderQueries.dob }, {})} className="list-column">
								Birth {this.renderSortIcon({ field: CLIENTS.contactOrderQueries.dob })}
							</div>
							<div onClick={() => this.sortBy({ field: CLIENTS.contactOrderQueries.sin }, {})} className="list-column">
								SIN {this.renderSortIcon({ field: CLIENTS.contactOrderQueries.sin })}
							</div>
						</div>

						{filteredContacts.map((contact, index) => {
							let odd = index % 2 === 0;
							return (
								<div
									className={`list-item ${odd ? "list-item--stripe" : ""}`}
									key={index}
									onClick={() => this.onContactSelect({ contactId: contact.id })}
								>
									<div className="list-column">
										{contact.first_name}
										{contact.Clients[0].ContactClient.is_primary && <span className="indicator">&#8226;</span>}
									</div>
									<div className="list-column">{contact.last_name}</div>
									<div className="list-column">{contact.email}</div>
									<div className="list-column">{contact.phone}</div>
									<div className="list-column">{contact.dob}</div>
									<div className="list-column">{contact.sin ? ClientService.concatSin({ sin: contact.sin }) : ""}</div>
								</div>
							);
						})}
					</div>
				)}

				<ContactsModal show={showContactModal} contactId={contactId} clientId={clientId} onClose={this.onContactClose} {...this.props} />
			</div>
		);
	}
}

export default Contacts;
