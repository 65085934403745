import React from "react";
import PropTypes from "prop-types";
import "./text-area-field.css";
import WComponent from "../WComponent";

class TextAreaField extends WComponent {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			name: "",
			value: "",
			label: "",
			type: "",
			disabled: "",
			autoComplete: ""
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.update({ [e.target.name]: e.target.value });
	}

	render() {
		let { type, className, name, value, onChange, disabled, autoComplete, title, setRef } = this.props;

		let classes = ["text-area-field__container__input"];

		if (className) {
			classes.push(className);
		}

		return (
			<div className="text-area-field__container">
				<span className="text-area-field__container__title">{title}</span>
				<textarea
					ref={setRef}
					type={type}
					className={classes.join(" ")}
					name={name}
					value={value}
					onChange={onChange}
					disabled={disabled}
					autoComplete={autoComplete}
				/>
			</div>
		);
	}
}

TextAreaField.propTypes = {
	title: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	autoComplete: PropTypes.string
};

TextAreaField.defaultProps = {
	type: "textarea"
};

export default TextAreaField;
