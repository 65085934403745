import React from "react";
import Modal from "react-responsive-modal";
import { withAlert } from "react-alert";

import WComponent from "../../common/WComponent";

import "./support-ticket-modal.css";

import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import TextAreaField from "../../common/text_area_field/TextAreaField";
import UtilityService from "../../../services/UtilityService";

class SupportTicketModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,

			name: "",
			description: ""
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show } = this.props;

		await this.update({
			show,

			name: "",
			description: ""
		});
	};

	onClose = async () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onSubmit = async () => {
		let { alert } = this.props;
		let { name, description } = this.state;

		if (!this.isValid()) {
			alert.error("Missing Fields.");
			return;
		}

		let newTicket = await UtilityService.submitTicket({ name, description });

		if (newTicket) {
			alert.success("Submitted new Support Ticket.");
		} else {
			alert.error("Could not submit support ticket");
		}

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	isValid() {
		let { name, description } = this.state;
		return name.length > 0 && description.length > 0;
	}

	render() {
		let { show, name, description } = this.state;
		let { onClose } = this.props;

		return (
			<Modal
				open={show}
				onClose={onClose}
				center
				classNames={{
					modal: "engagement-filter-modal__modal"
				}}
			>
				<div className="modal-title">Submit a Support Ticket ...</div>
				<div className="modal-content">
					<TextFieldInput title={"Title"} name="name" onChange={event => this.update({ name: event.target.value })} value={name} autoComplete="off" />
					<TextAreaField
						title={"Description"}
						name="description"
						onChange={event => this.update({ description: event.target.value })}
						value={description}
						autoComplete="off"
					/>
				</div>
				<div className="btn-group">
					<div className="btn" onClick={this.onSubmit}>
						Submit
					</div>
					<div className="btn" onClick={onClose}>
						Cancel
					</div>
				</div>
			</Modal>
		);
	}
}

export default withAlert(SupportTicketModal);
