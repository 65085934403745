import React from "react";
import * as Icon from "react-feather";

import WComponent from "../WComponent";
import SearchableDropdown from "../searchable_dropdown/SearchableDropdown";

// import "./user-assigner.css";

class ClientGroupAssigner extends WComponent {
	constructor(props) {
		super(props);

		/**
		 * props:
		 * hideRolePicker
		 * showNotificationToggle
		 */

		this.state = {
			selectedClientGroups: JSON.parse(JSON.stringify(props.clientGroups))
		};
	}

	componentDidUpdate(prevProps) {
		let { clientGroups } = this.props;

		if (prevProps.clientGroups.length !== clientGroups.length) {
			let selectedClientGroups = JSON.parse(JSON.stringify(clientGroups));

			this.update({
				selectedClientGroups
			});
		}
	}

	async componentDidMount() {}

	onSelectClientGroup = async clientGroup => {
		let { selectedClientGroups } = this.state;

		console.log(clientGroup);

		selectedClientGroups.push({
			client_group_id: clientGroup.id,

			// render
			clientGroupName: clientGroup.name
		});

		await this.update({ selectedClientGroups });

		if (this.props.onChange) {
			this.props.onChange({ selectedClientGroups });
		}
	};

	onRemoveClientGroup = async index => {
		let { selectedClientGroups } = this.state;

		selectedClientGroups.splice(index, 1);
		let newSelectedClientGroups = selectedClientGroups.slice();

		await this.update({
			selectedClientGroups: newSelectedClientGroups
		});

		if (this.props.onChange) {
			this.props.onChange({ selectedClientGroups });
		}
	};

	render() {
		let { selectedClientGroups } = this.state;

		return (
			<div className="user-assigner">
				<SearchableDropdown title="Add a client group ..." onSelect={this.onSelectClientGroup} type={"clientGroups"} clearOnSelect />
				<div className="user-assigner__sub">Current Client Groups:</div>
				{selectedClientGroups.length > 0 && (
					<div className="user-assigner__list">
						{selectedClientGroups.map((clientGroup, index) => {
							return (
								<div key={index} className="user-assigner__list__item-wrapper">
									<div className="user-assigner__list__item">
										<span className="user-assigner__list__item__employee-name">{clientGroup.clientGroupName}</span>
									</div>
									<div className="assigner__list__item__remove-btn">
										<Icon.XCircle
											className="assigner__list__item__remove-btn__icon"
											size={28}
											color="#FF6B6B"
											onClick={() => {
												this.onRemoveClientGroup(index);
											}}
										/>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

export default ClientGroupAssigner;
