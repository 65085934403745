import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/auth/Login.js";
import PrivateRoute from "./components/common/PrivateRoute.js";
import Main from "./components/layout/Main.js";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import "./App.css";

// optional cofiguration
const options = {
	position: "top right",
	timeout: 2500,
	offset: "50px",
	transition: "fade",
	zIndex: 999999999999999,
	containerStyle: {
		zIndex: 999999999999999
	}
};

class App extends Component {
	render() {
		return (
			<AlertProvider template={AlertTemplate} {...options}>
				<Router>
					<div className="App">
						<Switch>
							<Route exact path="/login" component={Login} />
							<PrivateRoute component={Main} path="/" />
						</Switch>
					</div>
				</Router>
			</AlertProvider>
		);
	}
}

export default App;
