import React from "react";
import PropTypes from "prop-types";
import "./inputBox.css";
import WComponent from "../WComponent";

class InputBox extends WComponent {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			name: "",
			type: ""
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.update({ [e.target.name]: e.target.value });
	}

	render() {
		let { type, name, checked, autoComplete, title } = this.props;

		return (
			<div className="input-box-container">
				<input type={type} className="input-box" name={name} onChange={this.props.onChange} checked={checked} autoComplete={autoComplete} />
				<span className="input-box-title">{title}</span>
			</div>
		);
	}
}

InputBox.propTypes = {
	title: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	autoComplete: PropTypes.string
};

InputBox.defaultProps = {
	type: "checkbox"
};

export default InputBox;
