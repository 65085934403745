import React from "react";
import * as Icon from "react-feather";

import WComponent from "../WComponent";
import SearchableDropdown from "../searchable_dropdown/SearchableDropdown";

// import "./user-assigner.css";

class ClientAssigner extends WComponent {
	constructor(props) {
		super(props);

		/**
		 * props:
		 * hideRolePicker
		 * showNotificationToggle
		 */

		this.state = {
			selectedClients: JSON.parse(JSON.stringify(props.clients))
		};
	}

	componentDidUpdate(prevProps) {
		let { clients } = this.props;

		if (prevProps.clients.length !== clients.length) {
			let selectedClients = JSON.parse(JSON.stringify(clients));

			this.update({
				selectedClients
			});
		}
	}

	async componentDidMount() {}

	onSelectClient = async client => {
		let { selectedClients } = this.state;

		selectedClients.push({
			client_id: client.id,

			// render
			clientName: client.name
		});

		await this.update({ selectedClients });

		if (this.props.onChange) {
			this.props.onChange({ selectedClients });
		}
	};

	onRemoveClient = async index => {
		let { selectedClients } = this.state;

		selectedClients.splice(index, 1);
		let newSelectedClients = selectedClients.slice();

		await this.update({
			selectedClients: newSelectedClients
		});

		if (this.props.onChange) {
			this.props.onChange({ selectedClients });
		}
	};

	render() {
		let { selectedClients } = this.state;

		return (
			<div className="user-assigner">
				<SearchableDropdown title="Add a client ..." onSelect={this.onSelectClient} type={"clients"} clearOnSelect />
				<div className="user-assigner__sub">Current Clients:</div>
				{selectedClients.length > 0 && (
					<div className="user-assigner__list">
						{selectedClients.map((client, index) => {
							return (
								<div key={index} className="user-assigner__list__item-wrapper">
									<div className="user-assigner__list__item">
										<span className="user-assigner__list__item__employee-name">{client.clientName}</span>
									</div>
									<div className="assigner__list__item__remove-btn">
										<Icon.XCircle
											className="assigner__list__item__remove-btn__icon"
											size={28}
											color="#FF6B6B"
											onClick={() => {
												this.onRemoveClient(index);
											}}
										/>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

export default ClientAssigner;
