import React from "react";
import * as Icon from "react-feather";
import { withAlert } from "react-alert";
import _ from "lodash";

import CLIENTS from "../../constants/Clients";

import ClientService from "../../services/ClientService";
import UserService from "../../services/UserService";

import Dropdown from "../common/dropdown/Dropdown";

import "../common/dropdown/dropdown.css";
import "./client-summary.css";
import Loader from "../common/Loader";
import EngagementService from "../../services/EngagementService";
import WComponent from "../common/WComponent";

class ClientSummary extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			summary: [],
			staff: [],
			partners: [],
			bookkeepers: [],

			search: "",
			errors: {},

			partnerId: 0,
			assignedUserId: 0,
			bookkeeperId: 0,
			type: CLIENTS.clientTypes.corporate.id,

			title: props.title,
			refresh: true,

			loading: true,
			limit: 50,
			offset: 0,
			loadedAll: false,

			sortField: null,
			sortOrder: "desc"
		};

		this.fetchSummaryDebounce = _.debounce(this.fetchSummary, 500, {
			leading: true,
			trailing: true
		});
	}

	componentDidMount = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.fetchUsers();
		await this.fetchPartners();
		await this.fetchFlows();
		await this.fetchSummary({});
	};

	fetchFlows = async () => {
		let flows = await EngagementService.fetchFlows({});

		await this.update({ flows });
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value
		});
		this.fetchSummaryDebounce();
	};

	onLoadMore = async () => {
		let { limit } = this.state;
		await this.update({
			limit: limit + 50
		});
		this.fetchSummary({ showLoading: false });
	};

	onPartnerSelect = async item => {
		await this.update({ partnerId: item.key });
		await this.fetchSummary({});
	};

	onAssignedSelect = async item => {
		await this.update({ assignedUserId: item.key });
		await this.fetchSummary({});
	};

	onBookkeeperSelect = async item => {
		await this.update({ bookkeeperId: item.key });
		await this.fetchSummary({});
	};

	onTypeSelect = async ({ type }) => {
		await this.update({ type });
		await this.fetchSummary({});
	};

	fetchSummary = async ({ showLoading = true }) => {
		let { limit, offset, partnerId, assignedUserId, bookkeeperId, type, search } = this.state;

		await this.update({
			loading: true && showLoading
		});

		let summary = await ClientService.fetchClientSummary({ limit, offset, partnerId, assignedUserId, bookkeeperId, type, search });

		if (!summary) {
			await this.update({
				loading: false && showLoading
			});

			return;
		}

		await this.update({
			loading: false && showLoading,
			loadedAll: summary.length < limit,
			summary
		});
	};

	fetchPartners = async () => {
		let partners = await UserService.fetchPartners({});

		await this.update({
			partners
		});
	};

	fetchUsers = async () => {
		let users = await UserService.fetchUsers({ groupId: 0, defaultValue: "All Assigned" });
		let bookkeepers = await UserService.fetchUsers({ groupId: 0, defaultValue: "All Bookkeepers" });

		await this.update({
			staff: users,
			bookkeepers
		});
	};

	sortBy = async field => {
		let { sortOrder } = this.state;

		await this.update({
			sortField: field,
			sortOrder: sortOrder === "desc" ? "asc" : "desc"
		});
		this.fetchSummary({ showLoading: false });
	};

	renderSortIcon = field => {
		let { sortField, sortOrder } = this.state;

		return sortField === field && (sortOrder === "desc" ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	renderEngagements = (engagements, flow) => {
		let list = engagements.filter(e => {
			return e.flow_id === flow.id;
		});

		return list.map(e => {
			// Get the flow state of the engagement
			let [flowState] = flow.flow_states.filter(flowState => {
				return flowState.id === e.flow_state_id && flowState.status !== "inactive";
			});

			if (!flowState) {
				return undefined;
			}

			return (
				<div
					key={e.id}
					className="s-list-eng"
					style={{ backgroundColor: flowState.color }}
					onClick={() => this.props.history.push(`/workflow/${flow.code}/${e.id}`)}
				>
					{flow.name} - {flowState.name}
					{/* {type === WORKFLOW.workflowTypes.t1.key ? e.fiscal_year_end.substring(0, 4) : e.fiscal_year_end} */}
				</div>
			);
		});
	};

	render() {
		let { partners, partnerId, assignedUserId, bookkeeperId, staff, bookkeepers, loading, search, loadedAll, summary, type, flows } = this.state;

		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Dashboard</h3>
						<h1 className="container-header-title__name">Client Summary</h1>
					</div>

					<div className="container-header-options">
						<div className="toggle-container">
							<div className="toggle">
								<button
									onClick={() => this.onTypeSelect({ type: CLIENTS.clientTypes.corporate.id })}
									className={type === CLIENTS.clientTypes.corporate.id ? "toggle--active" : ""}
								>
									Corporate
								</button>
							</div>
							<div className="toggle">
								<button
									onClick={() => this.onTypeSelect({ type: CLIENTS.clientTypes.personal.id })}
									className={type === CLIENTS.clientTypes.personal.id ? "toggle--active" : ""}
								>
									Personal
								</button>
							</div>
						</div>

						<Dropdown items={partners} selected={partnerId} onSelect={this.onPartnerSelect} />
						<Dropdown items={staff} selected={assignedUserId} onSelect={this.onAssignedSelect} />
						<Dropdown items={bookkeepers} selected={bookkeeperId} onSelect={this.onBookkeeperSelect} />
						<input
							className="container-search"
							type="search"
							name="search"
							placeholder="Search..."
							value={search}
							autoComplete="off"
							onChange={this.onSearch}
						/>
					</div>
				</div>

				{loading && (
					<div className="container-loader">
						<Loader />
					</div>
				)}

				{summary.length === 0 && !loading && (
					<div className="container-empty">
						<div>
							<Icon.Frown size={64} />
						</div>
						<div>... no summary ...</div>
					</div>
				)}

				{summary.length > 0 && !loading && (
					<div className="s-list-container">
						<div className="s-list-header s-list-item">
							<div className="s-list-column s-name">Client</div>

							{flows.map(flow => {
								return (
									<div key={flow.id} className="s-list-column">
										{flow.name}
									</div>
								);
							})}
						</div>

						{summary.map((client, index) => {
							return (
								<div className={`s-list-item ${index % 2 === 0 ? "s-list-item--stripe" : ""}`} key={index}>
									<div className="s-list-column s-name" onClick={() => this.props.history.push(`/clients/${type}/${client.id}`)}>
										{client.name}
									</div>
									{flows.map(flow => {
										return (
											<div key={flow.id} className="s-list-column">
												{this.renderEngagements(client.engagements, flow)}
											</div>
										);
									})}
								</div>
							);
						})}
						{!loadedAll && (
							<div className="s-list-load-more">
								<div className="btn btn--sm" onClick={this.onLoadMore}>
									Load More
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default withAlert(ClientSummary);
