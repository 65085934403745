import Kichiri from "./KichiriService";
import USERS from "../constants/Users";

const USER_KEY = "workflow_user";

var currentSetup = {
	userId: null,
	locationId: null,
	companyId: null,
	isSuperAdmin: false
};

const UserService = {
	/**
	 * Stores user data to the AsyncStorage
	 *
	 * @param {UserResponse} userData
	 * @return {Promise}
	 */
	storeUserData: userData => {
		try {
			let userDataString = JSON.stringify(userData);
			localStorage.setItem(USER_KEY, JSON.stringify(userData));
			return userDataString;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Clears the user data from AsyncStorage
	 *
	 * @return {Promise}
	 */
	clearUserData: () => {
		try {
			localStorage.removeItem(USER_KEY);
			return;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches only relevant user data for the app
	 *
	 * @return {Promise}
	 */
	getUserData: () => {
		try {
			let data = localStorage.getItem(USER_KEY);
			data = JSON.parse(data);

			if (!data) {
				return null;
			}

			let userId = currentSetup.userId || data.id;
			let companyId = currentSetup.companyId || data.company_id;
			let companyName = currentSetup.companyName || data.Company.name;
			let groupId = currentSetup.groupId || data.GroupPermission.id;
			let isSuperAdmin = currentSetup.isSuperAdmin || data.GroupPermission.id === 1;

			return {
				userId: userId,
				firstName: data.first_name,
				lastName: data.last_name,
				authToken: data.auth_token,
				companyId: companyId,
				groupId: groupId,
				companyName: companyName,
				isSuperAdmin: isSuperAdmin
			};
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Sets the current location and company ids for switching between locations and companies
	 *
	 * @param {Number} companyId
	 * @param {String} companyName
	 * @param {Number} locationId
	 * @param {String} locationName
	 */
	setUserCompanyAndLocation: ({ companyId, companyName }) => {
		currentSetup.companyId = companyId;
		currentSetup.companyName = companyName || "";
	},

	/**
	 * Fetches all the user data for the current session, should be used with caution
	 *
	 * @return {Promise}
	 */
	getFullUserData: () => {
		try {
			let data = localStorage.getItem(USER_KEY);
			data = JSON.parse(data);

			return data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Check to see if the user is already logged in
	 *
	 * @return {Promise}
	 */
	getAuthToken: () => {
		let data = null;
		try {
			data = localStorage.getItem(USER_KEY);
		} catch (error) {
			console.log(error);
		}

		return data;
	},

	/**
	 * Fetches a user via the userId
	 *
	 * @param {Number} userId
	 * @return {Promise}
	 */
	async fetchUser({ userId }) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.user.get({ userId }, {}, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches all the partner users
	 *
	 * @param {String}
	 * @return {Promise}
	 */
	async fetchPartners({ defaultValue = "All Partners" }) {
		let partners = await this.fetchUsers(1);

		partners.unshift({
			key: 0,
			value: defaultValue
		});

		return partners;
	},

	/**
	 * Fetches all the users for a company
	 *
	 * @param  {String}
	 * @return {Promise}
	 */
	async fetchAssignableUsers({ defaultValue = "Unassigned" }) {
		let users = await this.fetchUsers({});

		users.unshift({
			key: 0,
			value: defaultValue
		});

		return users;
	},

	/**
	 * Fetches the users from from the db
	 * @param {Number} groupId
	 * @param {String} defaultValue
	 * @param {Boolean} isDetailed
	 * @param {Array} sort
	 * @param {String} search
	 * @return {Promise<Array>}
	 */
	async fetchUsers({ groupId = 0, defaultValue = null, isDetailed = false, sort = null, search = "" }) {
		let userData = this.getUserData();

		try {
			if (!sort) {
				sort = [{ field: USERS.orderQueries.name, order: "asc" }];
			}

			let response = await Kichiri.user.list(
				{},
				{
					companyId: userData.companyId,
					groupId: groupId,
					sort,
					search
				},
				userData.authToken
			);

			let parsedResults = response.data.map(user => {
				if (isDetailed) {
					return user;
				} else {
					return {
						key: user.id,
						value: `${user.first_name} ${user.last_name}`
					};
				}
			});

			if (defaultValue) {
				parsedResults.unshift({
					key: 0,
					value: defaultValue
				});
			}
			return parsedResults;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Searches all the users in the given list
	 *
	 * @param {String} searchTerm
	 * @param {Array} groupId
	 * @return {Array}
	 */
	async searchUsers({ searchTerm, groupId }) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.user.search({ searchTerm, groupId }, {}, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Indicates whether the current user is an administrator
	 *
	 * @return {Boolean}
	 */
	isAdministrator() {
		let userData = this.getUserData();
		return userData.groupId === 1 || userData.groupId === 3;
	},

	/**
	 * Indicated whether the current user is a manager
	 * @returns {Boolean}
	 */
	isManager() {
		let userData = this.getUserData();
		return userData.groupId === 4;
	},

	/**
	 * Fetches all the teams for the current user's company
	 * @param {String} search
	 * @returns {Array}
	 */
	async fetchTeams({ search }) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.team.list(
				{},
				{
					companyId: userData.companyId,
					search
				},
				userData.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Get a specific team
	 * @param {Number} teamId
	 * @returns {Object}
	 */
	async fetchTeam({ teamId }) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.team.get({ teamId }, {}, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Updates a team
	 * @param {Number} teamId
	 * @param {String} name
	 * @param {String} description
	 * @param {Array} users
	 * @returns {Object}
	 */
	async updateTeam({ teamId, name, description, users }) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.team.update({ teamId, name, description, users }, {}, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Creates a team
	 * @param {String} name
	 * @param {String} description
	 * @param {String} users
	 * @returns {Object}
	 */
	async createTeam({ name, description, users }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.team.create({ name, description, users }, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches all the roles for the current user's company
	 * @param {String} searchTerm
	 * @returns {Array}
	 */
	async fetchRoles({ searchTerm }) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.role.list(
				{},
				{
					companyId: userData.companyId,
					searchTerm
				},
				userData.authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Get a specific role
	 * @param {Number} roleId
	 * @returns {Object}
	 */
	async fetchRole({ roleId }) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.role.get({ roleId }, {}, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update a users role
	 * @param {Number} roleId
	 * @param {String} name
	 * @param {String} description
	 * @returns {Object}
	 */
	async updateRole({ roleId, name, description }) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.role.update({ roleId, name, description }, {}, userData.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Create a role
	 * @param {String} name
	 * @param {String} description
	 * @returns {Object}
	 */
	async createRole({ name, description }) {
		let user = UserService.getUserData();

		try {
			let response = await Kichiri.role.create({ name, description }, {}, user.authToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Simple helper to fetch the user name for a particular user, may return empty string if the user doesn't exist
	 *
	 * @param {Number} userId
	 * @returns {String}
	 */
	async fetchUserName({ userId }) {
		if (!userId) {
			return "";
		}

		let user = await this.fetchUser({ userId });

		if (!user) {
			return "";
		}

		return `${user.first_name} ${user.last_name}`;
	},

	/**
	 * Simple helper to fetch the team name for a particular team, may return empty string if the team doesn't exist
	 *
	 * @param {Number} teamId
	 * @returns {String}
	 */
	async fetchTeamName({ teamId }) {
		if (!teamId) {
			return "";
		}

		let team = await this.fetchTeam({ teamId });

		if (!team) {
			return "";
		}

		return team.name;
	}
};

export default UserService;
