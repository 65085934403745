import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { withAlert } from "react-alert";
import * as Icon from "react-feather";
import { ExternalLink as IconExternalLink } from "lucide-react";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";

import UserService from "../../../services/UserService";
import EngagementService from "../../../services/EngagementService";
import RRuleEditor from "../../common/RRule/RRuleEditor";
import UtilityService from "../../../services/UtilityService";

import WComponent from "../../common/WComponent";
import Tasks from "../../tasks/Tasks";
import Notes from "../../notes/Notes";
import Events from "../../events/Events";
import NextEngagementModal from "../next_eng_modal/NextEngagementModal";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import SearchableDropdown from "../../common/searchable_dropdown/SearchableDropdown";
import UserAssigner from "../../common/user_assigner/UserAssigner";
import UserConstants from "../../../constants/Users";
import EngagementTemplateModal from "../EngagementTemplates/EngagementTemplateModal";

import "react-datepicker/dist/react-datepicker.css";

import "./engagement.css";

class Engagement extends WComponent {
	constructor() {
		super();
		this.state = {
			loading: true,
			engagement: null,
			search: "",
			errors: {},
			type: "t1",

			engagementId: 0,
			teamId: 0,
			clientId: 0,
			clientType: "",
			name: "",
			status: "",
			dateIn: new Date(),
			dueDate: new Date(),
			fiscalYearEnd: new Date(),
			notes: "",
			frequency: "",

			showDeleteModal: false,
			showNextModal: false,

			showUpdateModal: false,

			flow: {},
			flowStates: [],
			currentView: "details",

			selectedTeamName: "",
			selectedClientName: "",
			selectedFlowStateName: "",
			selectedFlowStateId: 0,

			assignedUsers: [],

			rrule: "",

			showCreateTemplateModal: false
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {
		if (prevProps.match.params.engagementId !== this.props.match.params.engagementId) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { flowCode, engagementId } = this.props.match.params;

		await this.update({ loading: true, flowCode, engagementId });

		await this.fetchFlowStates();
		await this.fetchEngagement();
		await this.fetchTeam();

		await this.update({ loading: false });
	};

	fetchTeam = async () => {
		let { teamId } = this.state;

		let team = await UserService.fetchTeam({ teamId });

		if (!team) {
			return;
		}

		await this.update({ selectedTeamName: team.name });
	};

	fetchFlowStates = async () => {
		let { flowCode } = this.props.match.params;

		let flowStates = await EngagementService.fetchFlowStates({ flowCode });

		if (UtilityService.isEmpty(flowStates)) {
			this.props.history.push("/workflow");
			this.props.alert.error(`Flow: '${flowCode}' not found`);
			return;
		}

		let flowId = flowStates[0].flow_id;
		let flow = await EngagementService.fetchFlow({ flowId });

		let map = {};
		for (let fs of flowStates) {
			map[fs.id] = fs;
			fs.key = fs.id;
			fs.value = fs.name;
		}

		await this.update({
			flowStates: flowStates,
			flowId,
			flow
		});
	};

	fetchEngagement = async () => {
		let { engagementId } = this.state;

		try {
			let { data: engagement } = await EngagementService.get({ engagementId });

			let assignedUsers = engagement.engagement_users.map(eu => {
				return {
					user_id: eu.user_id,
					role_id: eu.role_id,

					userName: `${eu.User.first_name} ${eu.User.last_name}`,
					roleName: `${eu.Role.name}`
				};
			});

			// Attach existing user title
			let tasks = engagement.tasks.map(task => {
				let roleName = null;

				if (task.task_users && task.task_users.length > 0) {
					roleName = task.task_users[0].Role.name;
				}

				return {
					...task,
					assignedUser: {
						name: roleName || UserConstants.roleType.NO_ROLE.value
					}
				};
			});

			await this.update({
				teamId: engagement.team_id || 0,
				clientId: engagement.Client ? engagement.Client.id : 0,
				clientType: engagement.Client ? engagement.Client.type : "",
				engagementId: engagement.id,
				flowId: engagement.flow_id,
				flowStateId: engagement.flow_state_id,

				name: engagement.name,
				status: engagement.status,
				dueDate: moment(engagement.due_date).toDate(),
				dateIn: moment(engagement.date_in).toDate(),
				fiscalYearEnd: moment(engagement.fiscal_year_end).toDate(),
				notes: engagement.notes,
				frequency: engagement.frequency,

				selectedClientName: engagement.Client ? engagement.Client.name : "No Assigned Client",
				selectedFlowStateName: engagement.flow_state.name,
				selectedFlowStateId: engagement.flow_state.id,

				assignedUsers,

				recurringEngagement: engagement.RecurringEngagement,
				tasks
			});
		} catch (error) {
			console.log(error);
		}
	};

	updateEngagement = async () => {
		let { engagementId, teamId, clientId, flowStateId, name, dueDate, dateIn, fiscalYearEnd, notes, assignedUsers, loading } = this.state;

		if (!this.isValid()) {
			this.props.alert.error("Missing fields!");
			return;
		}

		if (loading) {
			this.props.alert.info("Updating...");
			return;
		}

		await this.update({
			loading: true
		});

		try {
			dateIn = moment(dateIn).format("YYYY-MM-DD");
			dueDate = moment(dueDate).format("YYYY-MM-DD");
			fiscalYearEnd = moment(fiscalYearEnd).format("YYYY-MM-DD");

			let {
				data: { next }
			} = await EngagementService.update({
				engagementId,
				teamId,
				clientId,
				flowStateId,
				name,
				dueDate,
				dateIn,
				fiscalYearEnd,
				notes,
				assignedUsers
			});

			this.props.alert.success("Updated Successfully!");

			if (next) {
				await this.update({
					showNextModal: true
				});
			}
		} catch (error) {
			this.props.alert.error("Update Error!");
		}

		await this.update({
			loading: false
		});
	};

	onDeleteEngagement = async () => {
		let { engagementId } = this.state;

		this.update({
			loading: true
		});

		let success = await EngagementService.deleteEngagement({ engagementId });

		if (success) {
			this.props.alert.success("Deleted Successfully!");
		} else {
			this.props.alert.error("Delete Error!");
		}

		this.update({
			loading: false
		});
	};

	onFlowStateSelect = flowState => {
		this.update({
			flowStateId: flowState.id,
			selectedFlowStateName: flowState.name
		});
	};

	onClientSelect = item => {
		this.update({
			clientId: item.id,
			clientType: item.type
		});
	};

	onChange = event => {
		let { name, value } = event.target;

		this.update({
			[name]: value
		});
	};

	onBack = () => {
		this.props.history.goBack();
	};

	onDateInSelect = date => {
		this.update({
			dateIn: date
		});
	};

	onDueDateSelect = date => {
		this.update({
			dueDate: date
		});
	};

	handleChange = date => {
		this.update({
			fiscalYearEnd: date
		});
	};

	isDisabled() {
		return !this.isValid() || this.state.loading;
	}

	isValid = () => {
		let { name, fiscalYearEnd } = this.state;
		return name && name.length > 0 && fiscalYearEnd !== null;
	};

	onConfirmModalOpen = () => {
		this.update({
			showDeleteModal: true
		});
	};

	onUpdateModalOpen = () => {
		this.update({
			showUpdateModal: true
		});
	};

	onUpdateModalClose = ({ confirmed }) => {
		this.update({
			showUpdateModal: false
		});

		if (confirmed) {
			this.updateEngagement();
		}
	};

	onCloseDeleteModal = confirmed => {
		let { type } = this.state;
		let { history } = this.props;

		this.update({
			showDeleteModal: false
		});

		if (confirmed) {
			this.onDeleteEngagement();
			history.push(`/workflow/${type}`);
		}
	};

	onCloseUpdateModal = () => {
		this.update({
			showUpdateModal: false
		});
	};

	switchView = ({ view }) => {
		this.update({
			currentView: view
		});
	};

	onSelectTeam = async team => {
		await this.update({
			teamId: team.id,
			selectedTeamName: team.name
		});
	};

	onAssignedUsersChanged = async ({ selectedUsers }) => {
		await this.update({
			assignedUsers: selectedUsers
		});
	};

	onRRuleChange = async ({ rrule }) => {
		await this.update({ rrule });
	};

	onUpdateRecurringEngagementClicked = async () => {
		let { rrule, recurringEngagement, loading } = this.state;
		let { alert } = this.props;

		if (loading) {
			return;
		}

		await this.update({ loading: true });

		let updateAlert = alert.info("Updating ...", { timeout: 20000 });

		let success = await EngagementService.updateRecurringEngagement({
			recurringEngagementId: recurringEngagement.id,
			rrule
		});

		await this.update({ loading: false });

		alert.remove(updateAlert);

		if (!success) {
			alert.error("Unable to update Recurring Settings...");
		} else {
			alert.success("Recurring Engagement updated!");
		}
	};

	renderRepeat() {
		let { recurringEngagement } = this.state;
		let rrule = recurringEngagement.rrule;

		return (
			<div className="engagement-form-container">
				<RRuleEditor onChange={this.onRRuleChange} rrule={rrule} />
				<div className="btn-group">
					<div className="btn" onClick={this.onUpdateRecurringEngagementClicked}>
						Update
					</div>
				</div>
			</div>
		);
	}

	render() {
		let {
			// Toggles
			currentView,
			showDeleteModal,
			showUpdateModal,
			showNextModal,

			// Display Items
			selectedTeamName,
			selectedClientName,
			selectedFlowStateName,

			// Metadata
			flow,
			// flowStates,

			// Engagement Data
			engagementId,
			// teamId,
			clientId,
			clientType,
			// flowStateId,
			name,
			dateIn,
			dueDate,
			fiscalYearEnd,
			assignedUsers,

			recurringEngagement,
			showCreateTemplateModal
		} = this.state;

		return (
			<div>
				<div className="engagement-container">
					<div className="container-header">
						<div className="container-header-title">
							<h3 className="container-header-title__sub">Workflow - {flow.name}</h3>
							<div className="container-header-title__name">
								<div className="container-header-title__name__back" onClick={this.onBack}>
									<Icon.ArrowLeftCircle size={32} />
								</div>
								{name}
							</div>
						</div>
					</div>

					<div className="toggle-container engagement-toggle">
						<div className="toggle">
							<button className={currentView === "details" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "details" })}>
								Details
							</button>
						</div>
						<div className="toggle">
							<button className={currentView === "notes" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "notes" })}>
								Notes
							</button>
						</div>
						<div className="toggle">
							<button className={currentView === "tasks" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "tasks" })}>
								Tasks
							</button>
						</div>
						<div className="toggle">
							<button className={currentView === "events" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "events" })}>
								Events
							</button>
						</div>
						{recurringEngagement && (
							<div className="toggle">
								<button className={currentView === "repeats" ? "toggle--active" : ""} onClick={() => this.switchView({ view: "repeats" })}>
									Repeat Settings
								</button>
							</div>
						)}
					</div>

					{currentView === "details" && (
						<div className="engagement-form-container">
							<div className="engagement-form">
								<div className="engagement-form-section-1">
									<TextFieldInput title="Name" name="name" onChange={this.onChange} autoComplete="off" value={name} />
									<SearchableDropdown title="Client" onSelect={this.onClientSelect} type={"clients"} selectedName={selectedClientName} />
									<div className="view-client">
										<Link className="view-client__link" to={`/clients/${clientType}/${clientId}`} target="_blank">
											View Client
											<IconExternalLink className="view-client__link__icon" size={13} />
										</Link>
									</div>
									<SearchableDropdown title="Team" onSelect={this.onSelectTeam} type={"teams"} selectedName={selectedTeamName} showAll />
									<SearchableDropdown
										title="Status"
										onSelect={this.onFlowStateSelect}
										flowCode={flow.code}
										showAll
										type={"flowStates"}
										selectedName={selectedFlowStateName}
									/>
									{/* <Dropdown title="Status" items={flowStates} selected={flowStateId} onSelect={this.onFlowStateSelect} alternate={true} /> */}
									<div className="eng-text-field-container">
										<h3 className="eng-text-field-title">Date In</h3>
										<DatePicker
											className="eng-datepicker"
											selected={dateIn}
											onChange={this.onDateInSelect}
											timeIntervals={30}
											timeCaption="time"
											autoComplete="off"
											dateFormat="MMMM d, yyyy"
										/>
									</div>
									<div className="eng-text-field-container">
										<h3 className="eng-text-field-title">Due Date</h3>
										<DatePicker
											className="eng-datepicker"
											selected={dueDate}
											onChange={this.onDueDateSelect}
											timeIntervals={30}
											timeCaption="time"
											autoComplete="off"
											dateFormat="MMMM d, yyyy"
										/>
									</div>
									<div className="eng-text-field-container">
										<h3 className="eng-text-field-title">Fiscal Year End</h3>
										<DatePicker
											className="eng-datepicker"
											selected={fiscalYearEnd}
											onChange={this.handleChange}
											timeFormat="HH:mm"
											timeIntervals={15}
											dateFormat="MMMM d, yyyy"
											timeCaption="time"
											autoComplete="off"
										/>
									</div>
								</div>
								<div className="engagement-form-section-2">
									<UserAssigner onChange={this.onAssignedUsersChanged} users={assignedUsers} />
								</div>
							</div>
							<div className="btn-group">
								<div
									className="btn"
									onClick={() => {
										this.update({
											showCreateTemplateModal: true
										});
									}}
								>
									Create Template
								</div>
								<div className={"btn " + (this.isDisabled() ? "disable" : "")} onClick={this.onUpdateModalOpen}>
									Update
								</div>
								<div className="btn btn--icon" onClick={() => this.onConfirmModalOpen()}>
									<Icon.Trash2 size={22} />
								</div>
							</div>
						</div>
					)}

					{currentView === "repeats" && this.renderRepeat()}
				</div>
				<Modal open={showDeleteModal} onClose={this.onCloseDeleteModal} center>
					<div className="modal-title">Delete Engagement?</div>
					<div className="modal-content">Are you sure you want to remove this engagement?</div>
					<div className="btn-group">
						<div onClick={() => this.onCloseDeleteModal(true)} className="engagement-button">
							Yes
						</div>
						<div onClick={() => this.onCloseDeleteModal(false)} className="engagement-button">
							No
						</div>
					</div>
				</Modal>
				<Modal open={showUpdateModal} onClose={this.onCloseUpdateModal} center>
					<div className="modal-title">Update Engagement?</div>
					<div className="modal-content">Are you sure you want to update this engagement?</div>
					<div className="btn-group">
						<div onClick={() => this.onUpdateModalClose({ confirmed: true })} className="btn">
							Yes
						</div>
						<div onClick={() => this.onUpdateModalClose({ confirmed: false })} className="btn">
							No
						</div>
					</div>
				</Modal>

				<EngagementTemplateModal
					show={showCreateTemplateModal}
					onCreate={() => {
						this.props.alert.info("Created Template");
						this.update({ showCreateTemplateModal: false });
					}}
					onClose={() => {
						this.update({ showCreateTemplateModal: false });
					}}
					engagementTemplateId={"new"}
					name=""
					selectedFlow={{
						flowType: {
							id: this.state.flow.id,
							name: this.state.flow.name,
							code: this.state.flowCode
						},
						flowState: {
							id: this.state.selectedFlowStateId,
							name: this.state.selectedFlowStateName
						}
					}}
					selectedTeam={{
						id: this.state.teamId,
						name: this.state.selectedTeamName
					}}
					tasks={this.state.tasks}
				/>

				{currentView === "notes" && <Notes engagementId={engagementId} clientId={clientId} {...this.props} />}
				{currentView === "tasks" && <Tasks raw={true} title="Tasks" engagementId={engagementId} {...this.props} />}
				{currentView === "events" && <Events raw={true} title="Events" engagementId={engagementId} {...this.props} />}

				{engagementId && <NextEngagementModal show={showNextModal} engagementId={engagementId} />}
			</div>
		);
	}
}

export default withAlert(Engagement);
