import React from "react";
import "./tabs.css";

const Tab = ({ id, value, selected, onSelect }) => {
	let isSelected = id === selected;
	return (
		<div key={id} className={`tabs__item ${isSelected ? "tabs__item--active" : ""}`} onClick={() => onSelect({ id, value })}>
			{value}
		</div>
	);
};

export default Tab;
