import React, { Component } from "react";
import "./tabs.css";

class Tabs extends Component {
	render() {
		let { onSelect, selected, children } = this.props;

		const childrenWithProps = React.Children.map(children, child => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child, { onSelect, selected });
			}
			return child;
		});

		return <div className="tabs">{childrenWithProps}</div>;
	}
}

export default Tabs;
