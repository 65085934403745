import React from "react";
import * as Icon from "react-feather";
import { Link, Redirect } from "react-router-dom";
import WComponent from "../common/WComponent";
import moment from "moment";

import USERS from "../../constants/Users";

import Teams from "./Teams";

import UserService from "../../services/UserService";

import { withAlert } from "react-alert";

import "./users.css";
import Loader from "../common/Loader";

class Users extends WComponent {
	constructor() {
		super();
		this.state = {
			loading: false,
			currentView: "users",

			users: [],
			search: "",
			sort: [{ field: USERS.orderQueries.name, order: "asc" }]
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	async resetComponent() {
		this.update({
			loading: true
		});

		await this.fetchUsers();

		this.update({
			loading: false
		});
	}

	async fetchUsers() {
		let { sort, search } = this.state;

		let users = await UserService.fetchUsers({ isDetailed: true, sort, search });

		await this.update({
			users
		});
	}

	onSearch = async event => {
		await this.update({ search: event.target.value });
		await this.fetchUsers();
	};

	switchView(view) {
		this.update({
			currentView: view
		});
	}

	sortBy = async field => {
		let { sort } = this.state;

		let index = -1;
		for (let i = 0; i < sort.length; i++) {
			if (sort[i].field === field) {
				index = i;
				break;
			}
		}

		if (index === -1) {
			sort.push({ field, order: "desc" });
		} else {
			let sortField = sort[index];

			sort.splice(index, 1);
			if (sortField.order === "desc") {
				sortField.order = "asc";

				sort.push(sortField);
			}
		}

		await this.update({
			sort
		});

		await this.fetchUsers();
	};

	renderSortIcon = field => {
		let { sort } = this.state;

		let index = -1;
		for (let i = 0; i < sort.length; i++) {
			if (sort[i].field === field) {
				index = i;
				break;
			}
		}

		if (index === -1) {
			return null;
		}

		return sort[index].order === "desc" ? (
			<div style={{ fontSize: 10 }}>
				<Icon.ChevronUp size={10} />
				{index + 1}
			</div>
		) : (
			<div style={{ fontSize: 10 }}>
				<Icon.ChevronDown size={10} />
				{index + 1}
			</div>
		);
	};

	renderUsers() {
		let { users, loading, search } = this.state;

		let userData = UserService.getUserData();

		if (userData.groupId === 2) {
			return <Redirect to={"/dashboard"} />;
		}

		return (
			<>
				<div className="container-actions">
					<Link to={`/settings/users/new`}>
						<button className="container-add" type="button">
							<Icon.Plus size={18} />
						</button>
					</Link>
					<input
						className="container-search"
						type="search"
						name="search"
						placeholder="Search..."
						autoComplete="off"
						value={search}
						onChange={this.onSearch}
					/>
				</div>

				{loading && <Loader />}

				{users.length > 0 && !loading && (
					<div className="list-container">
						<div className="list-header list-item">
							<div onClick={() => this.sortBy(USERS.orderQueries.name)} className="list-column">
								Name {this.renderSortIcon(USERS.orderQueries.name)}
							</div>
							<div onClick={() => this.sortBy(USERS.orderQueries.title)} className="list-column">
								Role {this.renderSortIcon(USERS.orderQueries.title)}
							</div>
							<div onClick={() => this.sortBy(USERS.orderQueries.email)} className="list-column">
								Email {this.renderSortIcon(USERS.orderQueries.email)}
							</div>
							<div onClick={() => this.sortBy(USERS.orderQueries.phone)} className="list-column">
								Phone {this.renderSortIcon(USERS.orderQueries.phone)}
							</div>
							<div onClick={() => this.sortBy(USERS.orderQueries.createdAt)} className="list-column">
								Join Date {this.renderSortIcon(USERS.orderQueries.createdAt)}
							</div>
						</div>

						{users.map((user, index) => {
							let odd = index % 2 === 0;

							return (
								<div className={`list-item ${odd ? "list-item--stripe" : ""}`} key={index}>
									<div className="list-column" onClick={() => this.props.history.push(`/settings/users/${user.id}`)}>
										{user.first_name} {user.last_name}
									</div>
									<div className="list-column">{user.title}</div>
									<div className="list-column">{user.email}</div>
									<div className="list-column">{user.phone}</div>
									<div className="list-column">{moment(user.created_at).format("LL")}</div>
								</div>
							);
						})}
					</div>
				)}
			</>
		);
	}

	render() {
		let { currentView } = this.state;

		return (
			<>
				<div className="users">
					<div className="container-header">
						<div className="container-header-title">
							<h3 className="container-header-title__sub">Company</h3>
							<div className="container-header-title__name">Staff</div>
						</div>
					</div>

					<div className="toggle-container client-toggle-container">
						<div className="toggle">
							<button className={currentView === "users" ? "toggle--active" : ""} onClick={() => this.switchView("users")}>
								Users
							</button>
						</div>
						<div className="toggle">
							<button className={currentView === "teams" ? "toggle--active" : ""} onClick={() => this.switchView("teams")}>
								Teams
							</button>
						</div>
					</div>

					{currentView === "users" && this.renderUsers()}
					{currentView === "teams" && <Teams />}
				</div>
			</>
		);
	}
}

export default withAlert(Users);
