import React, { Component } from "react";
import * as Icon from "react-feather";

import "./checkbox.css";

class Checkbox extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	render() {
		let { title, name, checked, onChange, isSmall } = this.props;

		let checkboxStyles = ["checkbox"];
		let checkboxInputStyles = ["checkbox__input"];

		if (checked) {
			checkboxInputStyles.push("checkbox__input--selected");
		}

		if (isSmall) {
			checkboxStyles.push("checkbox--small");
			checkboxInputStyles.push("checkbox__input--small");
		}

		return (
			<div className={checkboxStyles.join(" ")}>
				<div
					type="checkbox"
					className={checkboxInputStyles.join(" ")}
					title={title}
					name={name}
					checked={checked}
					onClick={event => {
						onChange({ event, target: { checked: !checked, type: "checkbox", name } });
					}}
					autoComplete="off"
				>
					{checked && <Icon.Check size={16} color="white" />}
				</div>

				{title && <div className="checkbox__title">{title}</div>}
			</div>
		);
	}
}

export default Checkbox;
