import React, { Component } from "react";
import posed, { PoseGroup } from "react-pose";

import SearchableDropdown from "../searchable_dropdown/SearchableDropdown";

import "./floating-status-selector.css";

const Box = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		y: 10,
		x: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

class FloatingStatusSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			leftPos: 0,
			topPos: 0,
			selectedFlowStateName: "",
			flowCode: "t2"
		};

		this.floatingStatusSelectorRef = null;
		this.searchableDropdownComponentRef = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		document.addEventListener("mousedown", this.onHandleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onHandleClick, false);
	}

	onHandleClick = e => {
		if (this.floatingStatusSelectorRef && this.floatingStatusSelectorRef.contains(e.target)) {
			// the click is in the component
			return;
		}
		this.onClose();
	};

	onSelect = item => {
		let { onSelectCallback } = this.state;

		if (typeof onSelectCallback === "function") {
			onSelectCallback(item);
		}

		this.onClose();
	};

	onClose = async () => {
		await this.update({ show: false });

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	forceShow = async () => {
		await this.update({
			show: true
		});

		if (this.searchableDropdownComponentRef) {
			// this.searchableDropdownComponentRef.forceFocus();
		}
	};

	setFlowCode = flowCode => {
		this.update({
			flowCode
		});
	};

	setPosition = async ({ left, top }) => {
		let update = {};
		if (typeof left !== "undefined") update.leftPos = left;
		if (typeof top !== "undefined") update.topPos = top;
		await this.update(update);
	};

	setSelectCallback = async onSelectCallback => {
		await this.update({ onSelectCallback });
	};

	render() {
		let { show, leftPos, topPos, selectedFlowStateName, flowCode } = this.state;

		let style = {};

		if (topPos || leftPos) {
			if (topPos) {
				style.top = `${topPos}px`;
			}
			if (leftPos) {
				style.left = `${leftPos}px`;
			}
		} else {
			style.bottom = "20px";
		}

		return (
			<PoseGroup>
				{show && (
					<Box key="container" className="status-container" style={style} ref={ref => (this.floatingStatusSelectorRef = ref)}>
						{/* <div className="mb-emojis-x" onClick={this.onClose}>
							<Icon.X size="18" />
						</div> */}

						<SearchableDropdown
							ref={ref => (this.searchableDropdownComponentRef = ref)}
							title="Status"
							onSelect={this.onSelect}
							flowCode={flowCode}
							showAll
							alwaysShow={true}
							type={"flowStates"}
							selectedName={selectedFlowStateName}
						/>
					</Box>
				)}
			</PoseGroup>
		);
	}
}

export default FloatingStatusSelector;
