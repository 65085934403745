import React from "react";
import * as Icon from "react-feather";

class EngagementTaskCard extends React.Component {
	render() {
		const { task, index, onRemoveTask, onUpdateEngTask } = this.props;

		return (
			<div key={index} className="etm__tasks__task-card-wrapper">
				<div
					className="etm__tasks__task-card"
					onClick={() => {
						onUpdateEngTask(index);
					}}
				>
					<span className="etm__tasks__task-card__task-txt">{task.name}</span>

					<div className="etm__tasks__task-card__assigned-to">
						<span className="etm__tasks__task-card__assigned-to-txt">Assigned to:</span>
						<span> {task.assignedUser.name}</span>
					</div>
				</div>

				<div className="etm__tasks__task-card__remove-task">
					<Icon.XCircle
						className="etm__tasks__task-card__remove-task-icon"
						size={28}
						color="#FF6B6B"
						onClick={() => {
							onRemoveTask(index);
						}}
					/>
				</div>
			</div>
		);
	}
}

export default EngagementTaskCard;
