import React from "react";
import Modal from "react-responsive-modal";
import { withAlert } from "react-alert";
import SearchableDropdown from "../../../common/searchable_dropdown/SearchableDropdown";
import TextFieldInput from "../../../common/text_field_input/TextFieldInput";
import TextAreaField from "../../../common/text_area_field/TextAreaField";
import RelativeDate from "../../../common/RelativeDate/RelativeDate";
import { DEFAULT_RELATIVE_DUE_DATE } from "../../../../constants/EngagementTemplates";
import WComponent from "../../../common/WComponent";

class EngagementTaskModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			assignedUser: null,
			assignedUserName: "",
			deadline: DEFAULT_RELATIVE_DUE_DATE,
			notes: ""
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { action, taskToUpdateIndex, engTasks } = this.props;

		if (action === "update" && taskToUpdateIndex !== prevProps.taskToUpdateIndex) {
			const taskToUpdate = engTasks[taskToUpdateIndex];

			this.setState({
				name: taskToUpdate.name,
				assignedUser: taskToUpdate.assignedUser,
				assignedUserName: taskToUpdate.assignedUser.name,
				deadline: taskToUpdate.dueDate,
				notes: taskToUpdate.notes
			});
		}
	}

	resetComponent = () => {
		this.clearModal();
	};

	isValid = () => {
		let { name, assignedUser } = this.state;
		return name.length > 0 && assignedUser !== null;
	};

	onSubmit = () => {
		let { alert, action, onClose } = this.props;
		let { name, assignedUser, deadline, notes } = this.state;

		if (!this.isValid()) {
			alert.error("Missing fields!");
			return;
		}

		let data = {
			name,
			assignedUser,
			dueDate: deadline,
			notes
		};

		if (action === "update") {
			this.onUpdateTask(data);
		} else {
			this.onAddTask(data);
		}
		this.clearModal();
		onClose();
	};

	onAddTask = data => {
		let { engTasks } = this.props;
		engTasks.push(data);
	};

	onUpdateTask = data => {
		let { engTasks, taskToUpdateIndex } = this.props;
		engTasks[taskToUpdateIndex] = data;
	};

	clearModal = () => {
		this.setState({
			name: "",
			assignedUser: null,
			assignedUserName: "",
			deadline: DEFAULT_RELATIVE_DUE_DATE,
			notes: ""
		});
	};

	onRelativeDueDateChange = relativeDueDate => {
		this.setState({ deadline: relativeDueDate });
	};

	render() {
		const { show, onClose, loading, action } = this.props;
		const { name, assignedUserName, deadline, notes } = this.state;

		return (
			<Modal open={show} onClose={() => onClose({ confirmed: false, newTask: null })} center>
				<div className="modal-title">{"New Task Template"}</div>
				<div className="modal-content">
					{loading && <div className="loader">Loading ...</div>}
					{!loading && (
						<form className="task-modal-form">
							<div className="task-modal-form-container-1">
								<div className="task-modal-section-1-container">
									<TextFieldInput
										title="Task Name"
										name="name"
										onChange={event => {
											this.setState({ name: event.target.value });
										}}
										value={name}
										autoComplete="off"
									/>
									<RelativeDate onChange={this.onRelativeDueDateChange} data={deadline} />
								</div>
								<div className="task-modal-section-2-container">
									<SearchableDropdown
										title="Assigned Role"
										onSelect={user => {
											this.setState({ assignedUser: user, assignedUserName: user.name });
										}}
										selectedName={assignedUserName}
										type={"roles"}
										showAll
									/>
								</div>
							</div>
							<div className="task-modal-form-section-3">
								<div className="task-modal-notes">
									<TextAreaField
										title="Notes"
										type="text"
										name="notes"
										value={notes}
										onChange={event => {
											this.setState({ notes: event.target.value });
										}}
										className="task-modal-notes-input"
									/>
								</div>
							</div>
						</form>
					)}
				</div>
				<div className="btn-group">
					<div className="btn" onClick={this.onSubmit}>
						{action === "new" ? "Create Task" : "Update Task"}
					</div>
					<div className="btn" onClick={() => onClose({ confirmed: false })}>
						Cancel
					</div>
				</div>
			</Modal>
		);
	}
}

export default withAlert(EngagementTaskModal);
