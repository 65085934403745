import React from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import { withAlert } from "react-alert";
import moment from "moment";

import CLIENTS from "../../constants/Clients";

import UserService from "../../services/UserService";
import ConfigurationService from "../../services/ConfigurationService";
import ClientService from "../../services/ClientService";

import WComponent from "../common/WComponent";
import Checkbox from "../common/checkbox/Checkbox";
import ClientMultiAction from "./ClientMultiAction/ClientMultiAction";
import Loader from "../common/Loader";
import ClientFilterModal from "./ClientFilterModal/ClientFilterModal";
import ViewList from "../workflow/ViewList/ViewList";

import "./clients.css";

class Clients extends WComponent {
	constructor(props) {
		super();
		this.state = {
			search: "",
			loading: false,

			clients: [],
			desc: true,

			type: "corporate",

			filter: "active",

			filters: {},

			showFilterModal: false,
			showMultiActionModal: false,

			selectedClientsIds: [],

			isAllSelected: false,

			isViewChanged: false
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {
		let { clientType } = this.props.match.params;

		if (prevProps.match.params.clientType !== clientType) {
			this.resetComponent();
		}

		if (prevProps.location.state) {
			if (prevProps.location.state.resetComponent) {
				this.fetchClients();
				prevProps.location.state.resetComponent = false;
			}
		}
	};

	resetComponent = async () => {
		let { clientType } = this.props.match.params;

		await this.update({
			type: clientType,

			desc: true,
			filters: {}
		});

		await this.fetchClients();
		this.fetchPartners();
		this.sortBy({ field: "year_end" }, {});
	};

	fetchClients = async () => {
		let { type, search, filters } = this.state;

		await this.update({
			loading: true
		});

		let request = { type, searchTerm: search };

		request = Object.assign(filters, request);

		try {
			let response = await ClientService.search({ request });

			await this.update({
				clients: response.data
			});
		} catch (error) {
			console.log(error);
		}

		await this.update({
			loading: false
		});
	};

	fetchPartners = async () => {
		let partners = await UserService.fetchPartners({});

		await this.update({
			partners: partners
		});
	};

	sortBy = ({ field }, { date = false }) => {
		let { clients, desc } = this.state;

		this.update({
			sortedField: field,
			desc: !desc
		});

		clients = clients.sort((c1, c2) => {
			let a = c1[field];
			let b = c2[field];

			if (field === "partner") {
				a = c1.User ? c1.User.last_name : "Unassigned";
				b = c2.User ? c2.User.last_name : "Unassigned";
			}

			if (field === "contact-email") {
				a = c1.Contact ? c1.Contact.email : "";
				b = c2.Contact ? c2.Contact.email : "";
			}

			if (field === "contact-name") {
				a = c1.Contact ? c1.Contact.first_name : "";
				b = c2.Contact ? c2.Contact.first_name : "";
			}

			if (field === "manager") {
				a = c1.manager ? c1.manager.last_name : "Unassigned";
				b = c2.manager ? c2.manager.last_name : "Unassigned";
			}

			if (field === "team") {
				a = c1.team ? c1.team.name : "Unassigned";
				b = c2.team ? c2.team.name : "Unassigned";
			}

			if (date) {
				a = moment(a);
				b = moment(b);
			}

			if (a > b) {
				return desc ? -1 : 1;
			}

			if (a < b) {
				return desc ? 1 : -1;
			}

			return 0;
		});

		this.update({
			clients
		});
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value,
			customFields: [] // Clear the custom fields filters when searching via search term
		});

		await this.fetchClients();
	};

	onFilterChange = async ({ filters }) => {
		// { teamId, partnerId, seniorManagerId, managerId, customFields: [{ id: 12, value: "some value" }] } <- Anatomy of the filters object

		await this.update({
			search: "", // Clear search term when hard setting filters
			filters,

			isViewChanged: true
		});

		await this.fetchClients();
	};

	onShowFilterModal = async () => {
		await this.update({
			showFilterModal: true
		});
	};

	onClientFilterModalClose = () => {
		this.update({ showFilterModal: false });
	};

	onShowMultiActionModal = async () => {
		let { clients } = this.state;

		let selectedClients = clients.filter(client => {
			return client._isSelected === true;
		});

		if (selectedClients.length === 0) {
			this.props.alert.info("No Clients Selected");
			return;
		}

		let selectedClientsIds = selectedClients.map(client => client.id);

		await this.update({
			selectedClientsIds,
			showMultiActionModal: true
		});
	};

	onClientMultiActionModalClose = async () => {
		await this.update({
			showMultiActionModal: false
		});
	};

	onExport = async () => {
		let { clients } = this.state;
		let { authToken } = UserService.getUserData();

		let selectedClients = clients.filter(client => {
			return client._isSelected === true;
		});

		if (selectedClients.length === 0) {
			this.props.alert.info("No Clients Selected");
			return;
		}

		let selectedClientsIds = selectedClients.map(client => client.id);

		// let request = { clientIds: selectedClientsIds, authToken };
		// let downloadUrl = withQuery(`${ConfigurationService.resolveApi().rest}/client/export`, request);

		let downloadUrl = `${ConfigurationService.resolveApi().rest}/client/export`;

		let response = await fetch(downloadUrl, {
			method: "POST",
			headers: {
				authorization: authToken,
				Accept: "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				clientIds: selectedClientsIds
			})
		});

		let blob = await response.blob();

		// Tis a ghetto little thing
		let url = window.URL.createObjectURL(blob);
		let a = document.createElement("a");
		a.href = url;
		a.target = "_blank";
		a.download = `workflow-client-export-${moment().format("YYYY-MM-DD")}.csv`;
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	onSelectAll = e => {
		let value = e.target.checked;

		let { clients } = this.state;

		let newClients = clients.slice();

		for (let client of clients) {
			client._isSelected = value;
		}

		this.update({
			isAllSelected: value,
			newClients
		});
	};

	onClientSelected = clientId => {
		let { clients } = this.state;

		let newClients = clients.slice();

		for (let client of clients) {
			if (client.id === clientId) {
				if (typeof client._isSelected === "undefined") {
					client._isSelected = true;
				} else {
					client._isSelected = !client._isSelected;
				}
			}
		}

		this.update({
			clients: newClients
		});
	};

	getFilterSchema = () => {
		// TODO - Should add search to the view
		let { filters } = this.state;

		let schema = {
			filters,
			columns: [] // Add functionality later
			// sort: {
			// 	sortField,
			// 	sortOrder
			// }
		};

		return schema;
	};

	onViewSelected = async ({ view }) => {
		let { filters /* sort */ } = JSON.parse(view.schema);

		await this.update({
			filters,

			isViewChanged: false
		});

		await this.fetchClients();
		this.sortBy({ field: "year_end" }, {});
	};

	onDefaultViewSelected = async () => {
		await this.resetComponent();
	};

	renderPrimaryContact = ({ client }) => {
		if (client && client.Contacts[0]) {
			let c = client.Contacts[0];
			return `${c.first_name || ""} ${c.last_name || ""}`;
		}

		return "";
	};

	renderContactEmail = ({ client }) => {
		if (client && client.Contacts[0]) {
			let c = client.Contacts[0];
			return c.email || "";
		}

		return "";
	};

	renderContactPhone = ({ client }) => {
		if (client && client.Contacts[0]) {
			let c = client.Contacts[0];
			return c.phone || "";
		}

		return "";
	};

	renderManager = ({ client }) => {
		if (client && client.manager) {
			return `${client.manager.first_name} ${client.manager.last_name}`;
		}

		return "Unassigned";
	};

	renderSortIcon = ({ field }) => {
		let { sortedField, desc } = this.state;
		return sortedField === field && (desc ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	render() {
		let { loading, clients, type, search, showFilterModal, isAllSelected, showMultiActionModal, selectedClientsIds, isViewChanged, filters } = this.state;
		let { history } = this.props;

		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Clients</h3>
						<h1 className="container-header-title__name">{CLIENTS.clientTypes[type].value}</h1>
					</div>

					<div className="container-header-options">
						<button className="container-add" type="button" onClick={this.onShowMultiActionModal}>
							<Icon.Edit size={18} />
						</button>
						<button className="container-add" type="button" onClick={this.onShowFilterModal}>
							<Icon.Sliders size={18} />
						</button>
						<Link to={`/clients/${type}/new`}>
							<button className="container-add" type="button">
								<Icon.Plus size={18} />
							</button>
						</Link>
						<button className="container-add" onClick={this.onExport} type="button">
							<Icon.DownloadCloud size={18} />
						</button>
						<input
							className="container-search"
							type="search"
							name="search"
							placeholder={`Search Clients, Contacts, and Year Ends (eg. "Joeyco", "January") ...`}
							autoComplete="off"
							value={search}
							onChange={this.onSearch}
						/>
					</div>
				</div>

				<div className="test-container">
					<ViewList
						type={"client"}
						isViewChanged={isViewChanged}
						getSchema={this.getFilterSchema}
						onViewSelected={this.onViewSelected}
						onDefaultViewSelected={this.onDefaultViewSelected}
					/>

					{loading && (
						<div className="container-loader">
							<Loader />
						</div>
					)}

					{clients.length === 0 && !loading && (
						<div className="container-empty">
							<div>
								<Icon.Frown size={128} />
							</div>
							<div>... no clients ...</div>
						</div>
					)}

					{clients.length > 0 && !loading && (
						<div className="list-container">
							<div className="list-header list-item">
								<div onClick={() => {}} className="list-column list-column--xxs">
									<Checkbox name="selectAll" checked={isAllSelected} onChange={this.onSelectAll} isSmall />
								</div>
								<div onClick={() => this.sortBy({ field: "name" }, {})} className="list-column list-column--xxl">
									{type === "personal" ? "Contact" : "Organization"} {this.renderSortIcon({ field: "name" })}
								</div>
								{/* <div onClick={() => this.sortBy({ field: "partner" }, {})} className="list-column list-column--xs">
								Partner {this.renderSortIcon({ field: "partner" })}
							</div> */}
								<div onClick={() => this.sortBy({ field: "team" }, {})} className="list-column list-column--xs">
									Team {this.renderSortIcon({ field: "team" })}
								</div>
								<div onClick={() => this.sortBy({ field: "manager" }, {})} className="list-column list-column--s">
									Manager {this.renderSortIcon({ field: "manager" })}
								</div>
								<div onClick={() => this.sortBy({ field: "contact-name" }, {})} className="list-column">
									Primary {this.renderSortIcon({ field: "contact-name" })}
								</div>
								<div onClick={() => this.sortBy({ field: "contact-email" }, {})} className="list-column list-column--l">
									Email {this.renderSortIcon({ field: "contact-email" })}
								</div>
								<div className="list-column list-column--l">Phone</div>

								{/* <div className="list-column list-column--xs">Status</div> */}
								{type === "corporate" && (
									<div onClick={() => this.sortBy({ field: "year_end" }, {})} className="list-column">
										Year End {this.renderSortIcon({ field: "year_end" })}
									</div>
								)}
							</div>

							{clients.map((client, index) => {
								let odd = index % 2 === 0;

								return (
									<div className={`list-item ${odd ? "list-item--stripe" : ""}`} key={index}>
										<div className="list-column list-column--xxs">
											<Checkbox name="selectAll" checked={client._isSelected} onChange={() => this.onClientSelected(client.id)} isSmall />
										</div>
										<div className="list-column list-column--xxl" onClick={() => history.push(`/clients/${type}/${client.id}`)}>
											{client.name}
										</div>
										{/* <div className="list-column list-column--xs">{client.User ? `${client.User.initials}` : "Unassigned"}</div> */}
										<div className="list-column list-column--xs">{client.team ? `${client.team.name}` : "Unassigned"}</div>

										<div className="list-column list-column--s">{this.renderManager({ client })}</div>
										<div className="list-column ">{this.renderPrimaryContact({ client })}</div>
										<div className="list-column list-column--l">{this.renderContactEmail({ client })}</div>
										<div className="list-column list-column--l">{this.renderContactPhone({ client })}</div>
										{/* <div className="list-column list-column--xs">
										{client.status === "active" ? "" : client.status.charAt(0).toUpperCase() + client.status.substring(1)}
									</div> */}
										{type === "corporate" && <div className="list-column ">{moment(client.year_end).format("MMMM Do")}</div>}
									</div>
								);
							})}
						</div>
					)}
				</div>

				<ClientFilterModal show={showFilterModal} onClose={this.onClientFilterModalClose} onFilterChange={this.onFilterChange} filters={filters} />
				<ClientMultiAction show={showMultiActionModal} onClose={this.onClientMultiActionModalClose} clientIds={selectedClientsIds} {...this.props} />
			</div>
		);
	}
}

export default withAlert(Clients);
