// External Libraries
import React from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { withAlert } from "react-alert";
import queryString from "query-string";

// Services

// Common Components
import WComponent from "../../common/WComponent";

// Styling
import "./tools.css";
import ReconciliationTool from "./ReconciliationTool/ReconciliationTool";

const TOOLS = {
	reconciliationTool: {
		type: "reconciliationTool",
		title: "Reconciliation Tool",
		description: "Helper tool to reconcile CRA entries and the General Ledger for a given client",
		Icon: Icon.RefreshCcw
	}
};

class Tools extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedTool: null
		};
	}

	onSelectTool = type => {
		this.update({
			selectedTool: type
		});
	};

	renderToolOption(toolOption) {
		let { type, title, description, Icon } = toolOption;

		return (
			<div className="tools__item" onClick={() => this.onSelectTool(type)}>
				<div className="tools__item__icon">
					<Icon size={36} />
				</div>
				<div className="tools__item__text">
					<div className="tools__item__text__title">{title}</div>
					<div className="tools__item__text__blurb">{description}</div>
				</div>
			</div>
		);
	}

	render() {
		let { clientId } = this.props;
		let { selectedTool } = this.state;

		const toolsOptionArray = Object.values(TOOLS);

		return (
			<>
				{!selectedTool && (
					<div className="container">
						<div className="container__body">
							<div className="tools">{toolsOptionArray.map(toolOption => this.renderToolOption(toolOption))}</div>
						</div>
					</div>
				)}

				{selectedTool === TOOLS.reconciliationTool.type && <ReconciliationTool clientId={clientId} />}
			</>
		);
	}
}

export default withAlert(Tools);
