import React from "react";
import { RangeStepInput } from "react-range-step-input";

import UtilityService from "../../../services/UtilityService";

import Select from "../Select/Select";
import WComponent from "../WComponent";

import "./relative-date.css";

import { DEFAULT_RELATIVE_DUE_DATE } from "../../../constants/EngagementTemplates";

class RelativeDate extends WComponent {
	constructor(props) {
		super(props);

		let dateType;
		let value;

		try {
			dateType = props.data.dateType;
			value = props.data.value;
		} catch (error) {
			dateType = DEFAULT_RELATIVE_DUE_DATE.dateType;
			value = DEFAULT_RELATIVE_DUE_DATE.value;
		}

		this.state = {
			dateType,
			value
		};
	}

	async componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { data } = this.props;

		if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { data } = this.props;

		let dateType;
		let value;

		try {
			dateType = data.dateType;
			value = data.value;
		} catch (error) {
			dateType = DEFAULT_RELATIVE_DUE_DATE.dateType;
			value = DEFAULT_RELATIVE_DUE_DATE.value;
		}

		await this.update({
			dateType,
			value
		});
	}

	onDateTypeChange = async dateType => {
		let { value } = this.state;
		let { onChange } = this.props;

		await this.update({ dateType });

		if (onChange) {
			onChange({ dateType, value });
		}
	};

	onValueChange = async event => {
		let { dateType } = this.state;
		let value = event.target.value;

		let { onChange } = this.props;

		await this.update({ value });

		if (onChange) {
			onChange({ dateType, value });
		}
	};

	render() {
		let { dateType, value } = this.state;
		let { title } = this.props;

		return (
			<div className="relative-date">
				<div className="relative-date__select">
					<Select
						title={title || "Due Date"}
						onSearch={({ searchTerm }) => [
							{ id: "startOfTheMonth", name: "Start of the Month" },
							{ id: "dayOfTheMonth", name: "Day of the Month" },
							{ id: "endOfTheMonth", name: "End of the Month" },
							{ id: "daysAfter", name: "Days After" }
						]}
						onSelect={this.onDateTypeChange}
						selectedName={dateType.name}
						showAll
					/>
				</div>
				{dateType.id === "daysAfter" && (
					<>
						<RangeStepInput min={0} max={45} value={value} style={{ width: "100%" }} onChange={this.onValueChange} />
						<div className="relative-date__days">+ {value} days after</div>
					</>
				)}
				{dateType.id === "dayOfTheMonth" && (
					<>
						<RangeStepInput min={2} max={30} value={value} style={{ width: "100%" }} onChange={this.onValueChange} />
						<div className="relative-date__days">{UtilityService.getOrdinal(value)} of the month</div>
					</>
				)}
			</div>
		);
	}
}

export default RelativeDate;
