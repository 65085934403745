import React from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import "./login.css";

import { withAlert } from "react-alert";
import WComponent from "../common/WComponent";

class Login extends WComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			name: "",
			loading: false
		};
	}

	onEnter = e => {
		if (e.keyCode === 13) {
			e.preventDefault();
			this.signIn();
		}
	};

	signIn = async () => {
		let { email, password } = this.state;
		let { history, alert } = this.props;

		await this.update({
			loading: true
		});

		try {
			await AuthService.login({ email, password });

			let userData = UserService.getUserData();

			await this.update({
				loading: false
			});

			history.push("/");

			alert.success("Welcome " + userData.firstName + " !");
		} catch (error) {
			alert.error("Invalid username or password");
			console.log(error);
		}
	};

	onChange = e => {
		this.update({
			[e.target.name]: e.target.value
		});
	};

	render() {
		let userData = UserService.getUserData();

		if (userData) {
			return <Redirect to={"/"} />;
		}

		return (
			<div className="login-container">
				<form className="login-form-container">
					<div className="login-logo-container">
						<img className="login-logo" src={require("./srj_white_logo.png")} alt=""></img>
					</div>
					<div>
						<input
							className="login-email-field"
							type="text"
							name="email"
							placeholder="Email"
							value={this.state.email}
							onChange={this.onChange}
							autoComplete="off"
						/>
					</div>
					<div>
						<input
							className="login-password-field"
							type="password"
							name="password"
							placeholder="Password"
							value={this.state.password}
							onChange={this.onChange}
							onKeyDown={this.onEnter}
							autoComplete="off"
						/>
					</div>
					<div className="btn btn--login" onClick={this.signIn}>
						Sign In
					</div>
				</form>
			</div>
		);
	}
}

export default withAlert(Login);
