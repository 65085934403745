import React from "react";
import DatePicker from "react-datepicker";
import Modal from "react-responsive-modal";

import ClientService from "../../../services/ClientService";
import ContactService from "../../../services/ContactService";

import "./contact-modal.css";

import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import InputBox from "../../common/input_box/InputBox";

import * as Icon from "react-feather";
import WComponent from "../../common/WComponent";
import ClientPortalService from "../../../services/ClientPortalService";

class ContactsModal extends WComponent {
	constructor(props) {
		super(props);

		let { contactId, clientId } = this.props;

		this.state = {
			contactId,
			clientId,
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			dob: new Date(),
			sin: "",
			address: "",
			primary: false,
			loading: false,
			hasAccount: false,

			searchMode: true,
			show: false,
			searchTerm: "",
			results: [],

			showDeleteModal: false,

			emailSent: false
		};
	}

	componentDidUpdate = prevProps => {
		let { show, contactId } = this.props;

		if (prevProps.contactId !== contactId || (prevProps.show === false && show === true)) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { contactId, clientId, show } = this.props;

		await this.update({
			loading: true
		});

		let searchMode = contactId === "new";

		await this.update({
			contactId,
			clientId,
			show,
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			dob: new Date(),
			sin: "",
			address: "",
			searchMode
		});

		if (contactId !== "new") {
			await this.fetchContact();
		}

		await this.update({
			loading: false
		});
	};

	handleChange = e => {
		let target = e.target;
		let value = target.type === "checkbox" ? target.checked : target.value;

		this.update({
			[target.name]: value
		});
	};

	handleSinChange = e => {
		e.target.value = e.target.value.substring(0, 9);

		this.update({
			[e.target.name]: e.target.value
		});
	};

	isValid = () => {
		let { clientId, firstName, lastName, dob } = this.state;
		return clientId !== null && firstName.length > 0 && lastName.length > 0 && dob !== null;
	};

	clearModal = () => {
		let { contactId, clientId } = this.props;

		this.update({
			contactId,
			clientId,
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			dob: new Date(),
			sin: "",
			address: "",
			primary: false,
			loading: false
		});
	};

	fetchContact = async () => {
		let { contactId, clientId } = this.state;

		try {
			let response = await ContactService.getContact({ contactId, clientId });

			let contact = response.data;

			await this.update({
				clientId,
				firstName: contact.first_name,
				lastName: contact.last_name,
				email: contact.email,
				phone: contact.phone,
				dob: contact.dob,
				sin: contact.sin,
				address: contact.address,
				primary: contact.is_primary,
				hasAccount: contact.hasAccount
			});
		} catch (error) {
			console.log(error);
		}
	};

	createContact = async () => {
		let { loading, dob, clientId, firstName, lastName, email, phone, sin, address, primary } = this.state;
		let { alert } = this.props;

		let created = false;

		if (!this.isValid()) {
			// Call react-alert
			alert.error("Missing fields!");
			return created;
		}

		if (loading) {
			// Call react-alert
			alert.info("Updating...");
			return;
		}

		await this.update({
			loading: true
		});

		if (dob === "0000-00-00") {
			dob = null;
		}

		try {
			await ContactService.createContact({ clientId, firstName, lastName, email, phone, dob, sin, address, isPrimary: primary });

			created = true;
		} catch (error) {
			console.log(error);

			alert.error("Create error!");
			created = false;
		}

		await this.update({
			loading: false
		});

		this.clearModal();

		return created;
	};

	updateContact = async () => {
		let { alert } = this.props;
		let { contactId, clientId, firstName, lastName, email, phone, dob, sin, address, primary } = this.state;

		let updated = false;

		alert.info("Updating ...");

		await this.update({
			loading: true
		});

		try {
			await ContactService.updateContact({ contactId, clientId, firstName, lastName, email, phone, dob, sin, address, isPrimary: primary });
			updated = true;
			alert.success("Updated Successfully!");
		} catch (error) {
			console.log(error);
			alert.error("Updated error ...");
		}

		await this.update({
			loading: false
		});

		this.clearModal();

		return updated;
	};

	onContactsModalClose = async () => {
		let result = null;

		if (this.isNewContact()) {
			result = await this.createContact();
		} else {
			result = await this.updateContact();
		}

		if (result && this.props.onClose) {
			this.props.onClose();
		}
	};

	isNewContact = () => {
		let { contactId } = this.state;
		return contactId === "new";
	};

	onDateOfBirthSelect = date => {
		this.update({
			dob: date
		});
	};

	detectSinChange = e => {
		let { sin } = this.state;
		if (e.keyCode === 8) {
			e.preventDefault();
			// if the SIN number's length is greater than 0
			if (sin.length > 0) {
				this.update({
					sin: ClientService.concatSinMinus({ sin })
				});
			}
		}
	};

	onClose = () => {
		this.update({
			show: false
		});

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onSearch = async event => {
		let { value } = event.target;

		await this.update({
			searchTerm: value
		});

		let results = await ContactService.searchContacts({ searchTerm: value });
		await this.update({ results });
	};

	onContactSelect = async ({ contact }) => {
		let { clientId } = this.state;
		let { alert } = this.props;

		let success = await ContactService.assignContactToClient({ contactId: contact.id, clientId });

		if (!success) {
			alert.error("An error occurred trying to assign a contact.");
			return;
		}

		this.onClose();
		alert.success("Successfully assigned contact!");
	};

	onCreateNew = () => {
		this.update({
			searchMode: false
		});
	};

	renderModalTitle = () => {
		let { searchMode, contactId, firstName, lastName } = this.state;

		if (searchMode) {
			return <div className="contact-modal-title">Assign a contact ...</div>;
		}

		if (contactId === "new") {
			return <div className="contact-modal-title">New Contact</div>;
		}

		if (contactId !== "new") {
			let { hasAccount } = this.state;

			return (
				<div className="contacts-modal__header">
					<div className="contact-modal-title">
						{firstName} {lastName}
					</div>

					{!searchMode && hasAccount && !this.isNewContact() && (
						<div className="contacts-modal__header__account-created">
							<span className="contacts-modal__header__account-created__label">Account Created</span>
						</div>
					)}
				</div>
			);
		}
	};

	removeContact = async () => {
		let { clientId, contactId } = this.state;
		let { alert } = this.props;

		alert.info("Removing ...");

		await this.update({
			loading: true
		});

		try {
			await ContactService.removeContact({ clientId, contactId });
			alert.success("Contact successfully removed!");
		} catch (error) {
			console.log(error);
		}

		await this.update({
			loading: false
		});

		this.clearModal();

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onCloseDeleteModal = ({ confirmed }) => {
		this.update({
			showDeleteModal: false
		});

		if (confirmed) {
			this.removeContact();
		}
	};

	onConfirmModalOpen = () => {
		this.update({
			showDeleteModal: true
		});
	};

	sendAccSetupEmail = async () => {
		let { contactId } = this.state;

		let response = await ClientPortalService.emailNewAccountSetup({ contactId });

		if (response) {
			this.update({
				emailSent: true
			});
		}
	};

	sendResetPassEmail = async () => {
		let { email } = this.state;

		let response = await ClientPortalService.sendResetPassEmail({ contactEmail: email });

		if (response) {
			this.update({
				emailSent: true
			});
		}
	};

	render() {
		let { firstName, lastName, searchMode, show, searchTerm, results, email, phone, sin, dob, address, primary, emailSent, hasAccount } = this.state;
		let fullName = `${firstName} ${lastName}`;
		let { showDeleteModal } = this.state;

		if (!searchMode && emailSent) {
			return (
				<Modal open={show} onClose={this.onClose} center>
					<div className="contact-modal contact-modal--email-sent">
						<img className="contact-modal__check-icon" alt="icon check blue" src={require("./assets/icon-check-blue.gif")} />

						<span className="contact-modal--email-sent__title">Email sent!</span>
						<span className="contact-modal--email-sent__subtitle">Your contact will get an email shortly, regarding next steps.</span>

						<div
							className="button contact-modal--email-sent__ok-btn"
							onClick={() => {
								this.update({
									emailSent: false
								});
								this.onClose();
							}}
						>
							<span className="">Ok</span>
						</div>
					</div>
				</Modal>
			);
		}

		return (
			<Modal open={show} onClose={this.onClose} center>
				{this.renderModalTitle()}
				{searchMode && (
					<div className="contact-modal">
						<div className="contact-search">
							<TextFieldInput
								wrapperClassName="contact-modal__search__text-field-input-wrapper"
								className="contact-modal__search__text-field-input"
								title="Search for contacts to assign..."
								name="search"
								onChange={this.onSearch}
								value={searchTerm}
								autoComplete="off"
								placeholder="Search"
							/>
							<div className="button" onClick={this.onCreateNew}>
								Create ...
							</div>
						</div>

						<div className="contact-search-results">
							{results.map(result => {
								return (
									<div className="contact-search-results-item" onClick={() => this.onContactSelect({ contact: result })}>
										<div className="contact-search-results-item-name">
											{result.first_name} {result.last_name}
										</div>
										<div className="contact-search-results-item-email">{result.email}</div>
										<div className="contact-search-results-item-phone">{result.phone}</div>
										<div className="contact-search-results-item-sin">{result.sin}</div>
									</div>
								);
							})}
						</div>
					</div>
				)}

				{!searchMode && (
					<div className="contact-form">
						<div className="contact-form-column">
							<TextFieldInput title="First Name" name="firstName" onChange={this.handleChange} value={firstName} autoComplete="off" />
							<TextFieldInput title="Last Name" name="lastName" onChange={this.handleChange} value={lastName} autoComplete="off" />
							<TextFieldInput title="Email" name="email" onChange={this.handleChange} value={email} autoComplete="off" />
							<TextFieldInput title="Phone" name="phone" onChange={this.handleChange} value={phone} autoComplete="off" />
						</div>
						<div className="contact-form-column">
							<TextFieldInput
								title="SIN"
								name="sin"
								onChange={this.handleSinChange}
								// onKeyDown={this.detectSinChange}
								value={sin ? sin : ""}
								autoComplete="off"
							/>

							<div className="contact-modal-dob-container">
								<span className="contact-modal-dob-title">Date of Birth</span>
								<DatePicker
									selected={dob}
									onChange={this.onDateOfBirthSelect}
									timeFormat="HH:mm"
									timeIntervals={15}
									dateFormat="MMMM d, yyyy"
									timeCaption="time"
									className="contact-modal-datepicker"
									autoComplete="off"
								/>
							</div>
							<TextFieldInput title="Address" name="address" onChange={this.handleChange} value={address} autoComplete="off" />
							<div className="contacts-modal-input-box-container">
								<InputBox
									title="This is the Primary Contact."
									name="primary"
									checked={primary}
									onChange={this.handleChange}
									autoComplete="off"
								/>
							</div>
						</div>
					</div>
				)}
				<div className="contact-modal-btn-container">
					{!searchMode && !hasAccount && !this.isNewContact() ? (
						<div
							className="contact-form__acc-setup-link"
							onClick={() => {
								this.sendAccSetupEmail();
							}}
						>
							<span className="contact-form__acc-setup-link__txt">Send Account Setup Email</span>
							<Icon.Mail className="contact-form__acc-setup-link__icon"></Icon.Mail>
						</div>
					) : (
						<div
							className="contact-form__acc-setup-link"
							onClick={() => {
								this.sendResetPassEmail();
							}}
						>
							<span className="contact-form__acc-setup-link__txt">Send Reset Password Email</span>
							<Icon.Mail className="contact-form__acc-setup-link__icon"></Icon.Mail>
						</div>
					)}

					<div className="contact-modal-btn-container__btns">
						{!searchMode && (
							<div className="contact-btn-group">
								<div className="button" onClick={this.onContactsModalClose}>
									{this.isNewContact() ? "Create" : "Update"}
								</div>
							</div>
						)}

						{!searchMode && !this.isNewContact() && (
							<div className="">
								<div className="button remove-contact-btn" onClick={() => this.onConfirmModalOpen()}>
									<Icon.Trash2 size={22} />
								</div>
							</div>
						)}
					</div>
				</div>

				<Modal open={showDeleteModal} onClose={this.onCloseDeleteModal} center>
					<div className="modal-title">Remove Contact?</div>

					<div className="modal-content">{`Are you sure you want to remove ${fullName} as a contact?`}</div>

					<div className="btn-group">
						<div onClick={() => this.onCloseDeleteModal({ confirmed: true })} className="engagement-button">
							Yes
						</div>
						<div onClick={() => this.onCloseDeleteModal({ confirmed: false })} className="engagement-button">
							No
						</div>
					</div>
				</Modal>
			</Modal>
		);
	}
}

export default ContactsModal;
