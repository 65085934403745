import React, { Component } from "react";
import PropTypes from "prop-types";
import "./textFieldInput.css";

class TextFieldInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			title: "",
			name: "",
			value: "",
			label: "",
			type: "",
			disabled: "",
			autoComplete: "off"
		};
	}

	render() {
		let { title, style, type, className, wrapperClassName, name, value, onChange, disabled, autoComplete, onKeyDown, placeholder } = this.props;

		return (
			<div className={wrapperClassName ? wrapperClassName : "text-field-input-container"}>
				{title && <span className="text-field-input-title">{title}</span>}
				<input
					style={style}
					type={type}
					className={className ? className : "text-field-input"}
					name={name}
					value={value}
					onChange={onChange}
					disabled={disabled}
					autoComplete={autoComplete}
					onKeyDown={onKeyDown}
					placeholder={placeholder}
				/>
			</div>
		);
	}
}

TextFieldInput.propTypes = {
	title: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	autoComplete: PropTypes.string,
	onKeyDown: PropTypes.func,
	width: PropTypes.string
};

TextFieldInput.defaultProps = {
	type: "text"
};

export default TextFieldInput;
