import React from "react";
import * as Icon from "react-feather";
import { Pin as IconPin, PinOff as IconPinOff } from "lucide-react";
import moment from "moment";
import { withAlert } from "react-alert";

import NoteService from "../../services/NoteService";
import NoteModal from "./NoteModal";

// import "./tasks.css";
import { ORDER_QUERIES } from "../../constants/Tasks";
import WComponent from "../common/WComponent";

class Notes extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,

			notes: [],
			noteId: "new"

			// filter: "active",
		};

		this.noteModal = null;
		// this.onSearch = this.onSearch.bind(this);
		// this.onFilterSelect = this.onFilterSelect.bind(this);
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let newProps = this.props;
		let oldProps = prevProps;

		if (oldProps.engagementId !== newProps.engagementId || oldProps.clientId !== newProps.clientId) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { engagementId, clientId, pinnedOnly } = this.props;

		await this.update({
			engagementId: engagementId || 0,
			clientId: clientId || 0,
			loading: true,
			pinnedOnly: pinnedOnly ? true : undefined
		});

		await this.fetchNotes();
		await this.update({ loading: false });
	}

	async fetchNotes() {
		let { engagementId, clientId, pinnedOnly } = this.state;
		let notes = await NoteService.fetchNotes({ clientId, engagementId, isPinned: pinnedOnly });

		await this.update({
			notes
		});
	}

	// sortTasks(tasks) {
	//     return tasks.sort((t1, t2) => {
	//         if (t1.step > t2.step) {
	//             return 1;
	//         }

	//         if (t1.step < t2.step) {
	//             return -1;
	//         }

	//         return 0;
	//     });
	// }

	// onSearch(event) {
	//     this.setState({
	//         search: event.target.value,
	//     });
	// }

	onNewTask = async () => {
		await this.update({ noteId: "new" });
		this.noteModal.show();
	};

	onNoteSelect = async note => {
		await this.update({ noteId: note.id });
		this.noteModal.show();
	};

	onNoteClose = async () => {
		await this.update({ loading: true });
		await this.fetchNotes();
		await this.update({ loading: false });
	};

	onPinNote = async note => {
		let success = await NoteService.updateNote({
			noteId: note.id,
			isPinned: !note.is_pinned
		});

		if (!success) {
			this.props.alert.info("Failed to pin note.");
		} else {
			this.props.alert.info("Note updated!");
		}

		await this.fetchNotes();
	};

	// onFilterSelect(filter) {
	//     this.setState({ filter }, this.fetchTasks);
	// }

	renderSortIcon(field) {
		let { sortField, sortOrder } = this.state;

		return sortField === field && (sortOrder === "desc" ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	}

	render() {
		let { loading, notes, noteId, pinnedOnly } = this.state;

		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Workflow</h3>
						<h1 className="container-header-title__name">Notes</h1>
					</div>
					<div className="container-header-options">
						<button className="container-add" type="button" onClick={this.onNewTask}>
							<Icon.Plus size={18} />
						</button>
					</div>
				</div>

				{loading && <div className="container-loader">Loading ...</div>}

				{notes.length === 0 && !loading && (
					<div className="container-empty">
						<div>
							<Icon.Frown size={64} />
						</div>
						<div>... no notes ...</div>
					</div>
				)}

				{notes.length > 0 && !loading && (
					<div className="list-container">
						<div className="list-header list-item">
							<div className="list-column list-column--xxs" />
							<div onClick={() => this.sortBy(ORDER_QUERIES.name)} className="list-column list-column--l">
								Note {this.renderSortIcon(ORDER_QUERIES.name)}
							</div>
							<div onClick={() => this.sortBy(ORDER_QUERIES.description)} className="list-column list-column--xxs">
								Author {this.renderSortIcon(ORDER_QUERIES.description)}
							</div>
							<div onClick={() => this.sortBy(ORDER_QUERIES.assignedUser)} className="list-column list-column--xxs">
								Date {this.renderSortIcon(ORDER_QUERIES.assignedUser)}
							</div>
						</div>

						{notes.map((note, index) => {
							let odd = index % 2 === 0;
							return (
								<div className={`list-item ${odd ? "list-item--stripe" : ""}`} key={index}>
									<div className="list-column list-column--xxs" onClick={() => this.onPinNote(note)}>
										{note.is_pinned ? <IconPinOff size={14} /> : <IconPin size={14} />}
									</div>

									<div className="list-column list-column--l" onClick={() => this.onNoteSelect(note)}>
										{note.content}
									</div>
									<div className="list-column list-column--xxs">
										{note.User ? `${note.User.first_name} ${note.User.last_name}` : "Unassigned"}
									</div>
									<div className="list-column list-column--xxs">{moment(note.created_at).format("MMMM Do YYYY @ h:mma")}</div>
								</div>
							);
						})}
					</div>
				)}

				<NoteModal ref={ref => (this.noteModal = ref)} noteId={noteId} shouldPin={pinnedOnly} onClose={this.onNoteClose} {...this.props} />
				{/* <TaskModal ref={(ref) => (this.taskModal = ref)} taskId={this.state.taskId} engagementId={this.state.engagementId} onClose={this.onTaskClose} {...this.props} /> */}
			</div>
		);
	}
}

export default withAlert(Notes);
