import React from "react";
import { Link, Redirect } from "react-router-dom";
import UserService from "../../services/UserService";
import EngagementService from "../../services/EngagementService";
import "./navbar.css";
import SupportTicketModal from "./SupportTicketModal/SupportTicketModal";
import AuthService from "../../services/AuthService";
import WComponent from "../common/WComponent";

class Navbar extends WComponent {
	constructor() {
		super();
		this.state = {
			loading: false,

			flows: [],

			showTicketModal: false
		};
	}

	signOut = async () => {
		this.update({
			loading: true
		});

		try {
			await AuthService.logout();
		} catch (error) {
			console.log(error);
		}

		this.update({
			loading: false
		});
	};

	componentDidMount = async () => {
		let flows = await EngagementService.fetchFlows({});
		await this.update({
			flows
		});
	};

	onSubmitATicket = () => {
		this.update({
			showTicketModal: true
		});
	};

	onSupportTicketModalClose = () => {
		this.update({
			showTicketModal: false
		});
	};

	render() {
		let { flows, showTicketModal } = this.state;

		let userData = UserService.getUserData();

		if (!userData) {
			return <Redirect to={"/login"} />;
		}

		let protectedNavLinks = null;
		let administratorNavLinks = [
			{ to: "/settings/users", label: "Users" },
			{ to: "/settings/templates", label: "Templates" },
			{ to: "/settings/flows", label: "Flows" },
			{ to: "/settings/fields", label: "Fields" },
			{ to: "/settings/company", label: "Company" }
		];

		let managerNavLinks = [
			{ to: "/settings/users", label: "Users" },
			{ to: "/settings/templates", label: "Templates" },
			{ to: "/settings/flows", label: "Flows" },
			{ to: "/settings/fields", label: "Fields" }
		];
		if (UserService.isAdministrator()) {
			protectedNavLinks = (
				<>
					{administratorNavLinks.map(link => {
						return (
							<Link key={link.to} className="navbar-link" to={link.to}>
								{link.label}
							</Link>
						);
					})}
				</>
			);
		} else if (UserService.isManager()) {
			protectedNavLinks = (
				<>
					{managerNavLinks.map(link => {
						return (
							<Link key={link.to} className="navbar-link" to={link.to}>
								{link.label}
							</Link>
						);
					})}
				</>
			);
		}

		return (
			<>
				<div className="side-navbar-container">
					<div className="side-navbar-items">
						<div className="navbar-logo">
							<img className="logo" src={require("./srj_white_logo.png")} alt=""></img>
						</div>

						<div className="navbar-item">
							<h2 className="navbar-item-title">Overview</h2>
							<Link className="navbar-link" to="/">
								Client Summary
							</Link>
							<Link className="navbar-link" to="/overview/my-engagements">
								My Engagements
							</Link>
							<Link className="navbar-link" to="/overview/my-tasks">
								My Tasks
							</Link>
						</div>

						<div className="navbar-item">
							<h2 className="navbar-item-title">Clients</h2>
							<Link className="navbar-link" to="/clients/corporate">
								Corporate
							</Link>
							<Link className="navbar-link" to="/clients/personal">
								Personal
							</Link>
							<Link className="navbar-link" to="/clients/trust">
								Trusts
							</Link>
							<Link className="navbar-link" to="/clients/groups">
								Groups
							</Link>
						</div>

						<div className="navbar-item">
							<h2 className="navbar-item-title">Workflow</h2>
							{flows.map(f => {
								return (
									<Link key={f.id} className="navbar-link" to={`/workflow/${f.code}`}>
										{f.name}
									</Link>
								);
							})}
							<Link key="all" className="navbar-link" to={`/workflow`}>
								All Flows
							</Link>
						</div>

						<hr className="navbar-line-breaker"></hr>

						<div className="navbar-item">
							<h2 className="navbar-item-title">Settings</h2>
							{protectedNavLinks}

							<Link className="navbar-link" to={`/settings/users/${userData.userId}`}>
								Edit Profile
							</Link>

							<span className="navbar-link" onClick={this.onSubmitATicket}>
								Submit a Ticket
							</span>

							<span className="navbar-link" onClick={this.signOut}>
								Sign Out
							</span>
						</div>
					</div>
				</div>
				<SupportTicketModal show={showTicketModal} onClose={this.onSupportTicketModalClose} />
			</>
		);
	}
}

export default Navbar;
