// External Libraries
import React from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import { withAlert } from "react-alert";
import posed from "react-pose";

// Services
import UserService from "../../../services/UserService";
import EngagementService from "../../../services/EngagementService";

// Common Components
import ViewModal from "../ViewModal/ViewModal";
import WComponent from "../../common/WComponent";

const Box = posed.div({
	visible: {
		width: 300,
		padding: 15,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	hidden: {
		width: 0,
		padding: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

class ViewList extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			views: [],
			isViewChanged: false,

			savingView: false,
			overwritingView: false,

			showViews: true,
			minimizeViews: false,

			showViewModal: false,
			selectedViewId: null,
			shouldForceDefaultView: true
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = async prevProps => {
		let viewChanged = prevProps.isViewChanged !== this.props.isViewChanged;
		let shouldForceDefaultView = prevProps.shouldForceDefaultView !== this.props.shouldForceDefaultView && this.props.shouldForceDefaultView === true;

		if (viewChanged || shouldForceDefaultView) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { selectedViewId } = this.state;
		let { shouldForceDefaultView, isViewChanged } = this.props;

		let newSelectedViewId = null;
		if (!shouldForceDefaultView) {
			newSelectedViewId = selectedViewId;
		}

		await this.update({
			selectedViewId: newSelectedViewId,
			isViewChanged
		});

		await this.fetchViews();
	};

	fetchViews = async () => {
		let { type } = this.props;
		let views = await EngagementService.fetchViews({ type });

		await this.update({
			views
		});
	};

	onCreateNewView = async () => {
		this.update({
			selectedViewId: null,

			showViewModal: true,
			isViewChanged: false
		});
	};

	onViewModalClose = async () => {
		this.update({
			showViewModal: false
		});
	};

	onViewDelete = async ({ viewId, status }) => {
		let { alert } = this.props;

		await this.update({
			savingView: true
		});

		let view = await EngagementService.updateView({ viewId, status });

		await this.update({
			showViewModal: false,
			isViewChanged: false,
			savingView: false
		});

		if (!view) {
			alert.success("An error occurred trying to delete this view.");
		} else {
			alert.success("View Deleted Successfully!");
		}

		await this.fetchViews();
	};

	onViewSave = async ({ viewId, name, isShared, assignedUsers: assignedUsersForView }) => {
		let { savingView, overwritingView } = this.state;
		let { alert, type } = this.props;

		let isNewView = viewId === null;

		if (savingView || overwritingView) {
			return;
		}

		await this.update({
			savingView: true
		});

		let schema = this.props.getSchema();

		schema = JSON.stringify(schema, null, 4);

		let userIds = assignedUsersForView.map(user => {
			return user.user_id;
		});

		let { userId } = UserService.getUserData();

		// Check if the requester user id exists in the list
		let [exists] = userIds.filter(id => {
			return id === userId;
		});

		// If they don't then add them to the list
		if (!exists) {
			userIds.push(userId);
		}

		let view = null;

		if (isNewView) {
			view = await EngagementService.createView({ name, schema, userIds, isShared, type });
		} else {
			view = await EngagementService.updateView({ viewId, name, schema, userIds, isShared, type });
		}

		await this.update({
			showViewModal: false,
			isViewChanged: false,
			savingView: false
		});

		if (!view) {
			alert.success("An error occurred trying to create a view.");
		} else {
			alert.success("View Created Successfully!");
		}

		await this.fetchViews();
	};

	onDefaultViewSelected = async () => {
		await this.update({
			selectedViewId: null
		});

		if (this.props.onDefaultViewSelected) {
			this.props.onDefaultViewSelected();
		}
	};

	onViewEditSelected = async ({ event, view }) => {
		event.stopPropagation();
		event.preventDefault();

		await this.update({
			selectedViewId: view.id,
			showViewModal: true
		});
	};

	onViewSelected = async ({ event, view }) => {
		await this.update({
			selectedViewId: view.id,
			isViewChanged: false
		});

		this.props.onViewSelected({ view });
	};

	onOverwriteCurrentView = async () => {
		let { selectedViewId, savingView, overwritingView } = this.state;
		let { alert, type } = this.props;

		if (savingView || overwritingView) {
			return;
		}

		this.update({
			overwritingView: true
		});

		// Retrieve the view "schema" from the parent
		// Example payload:
		// {
		// 	filters: { flowId, flowStateId, ... },
		// 	sort: { ... }
		// 	columns: { ... }
		// }
		let schema = this.props.getSchema();

		schema = JSON.stringify(schema, null, 4);

		let updatedView = await EngagementService.updateView({ viewId: selectedViewId, schema, type });

		if (!updatedView) {
			alert.success("An error occurred trying to overwrite this view.");
		} else {
			alert.success("View updated successfully!");
		}

		await this.update({
			isViewChanged: false,
			overwritingView: false
		});

		await this.fetchViews();
	};

	isDefaultViewSelected = () => {
		let { selectedViewId } = this.state;

		return selectedViewId === null;
	};

	onToggleViews = () => {
		let { minimizeViews } = this.state;
		this.update({
			minimizeViews: !minimizeViews
		});
	};

	renderViews() {
		let { isViewChanged, views, selectedViewId, savingView, overwritingView, showViews, minimizeViews } = this.state;

		if (!showViews) {
			return null;
		}

		return (
			<div className="views">
				<Box className="views-container" pose={minimizeViews ? "hidden" : "visible"}>
					{!minimizeViews && (
						<>
							<div className="views-container__title">Views</div>
							{isViewChanged && <div className="views-container__unsaved">(Unsaved Changes)</div>}
							<div className="views-container__list">
								<div
									className={`views-container__list__item ${this.isDefaultViewSelected() ? "views-container__list__item--selected" : ""}`}
									onClick={this.onDefaultViewSelected}
								>
									Default
								</div>

								{views.map(view => {
									return (
										<div
											className={`views-container__list__item ${
												selectedViewId === view.id ? "views-container__list__item--selected" : ""
											}`}
											onClick={event => this.onViewSelected({ event, view })}
											key={view.id}
										>
											<div>{view.name}</div>
											<div className="views-container__list__item__action" onClick={event => this.onViewEditSelected({ event, view })}>
												<Icon.Edit2 size={20} />
											</div>
										</div>
									);
								})}
							</div>
							{isViewChanged && (
								<div className="views-container__actions">
									<div className="btn" onClick={this.onCreateNewView}>
										{!savingView ? "Create new view" : "Saving ..."}
									</div>
									{!this.isDefaultViewSelected() && (
										<div className="btn" onClick={this.onOverwriteCurrentView}>
											{!overwritingView ? "Overwrite current view" : "Saving ..."}
										</div>
									)}
								</div>
							)}
						</>
					)}
				</Box>
				<div className="views-container__arrow" onClick={this.onToggleViews}>
					{minimizeViews ? <Icon.Trello size={18} /> : <Icon.ChevronLeft size={18} />}
				</div>
			</div>
		);
	}

	viewHasColumn(columnId) {
		let { columns } = this.state;

		if (_.isEmpty(columns)) {
			return true;
		}

		return columns[columnId];
	}

	render() {
		let { showViewModal, selectedViewId } = this.state;

		return (
			<>
				{this.renderViews()}
				<ViewModal viewId={selectedViewId} show={showViewModal} onClose={this.onViewModalClose} onSave={this.onViewSave} onDelete={this.onViewDelete} />
			</>
		);
	}
}

export default withAlert(ViewList);
