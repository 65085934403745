import React from "react";
import PropTypes from "prop-types";

import "./dropdown.css";

import WComponent from "../../common/WComponent";

class Dropdown extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			items: props.items || [],
			selected: "",
			displayTextColor: "",
			classChange: "status-dropdown-display-container"
		};

		this.inlineSelect = null;

		this.node = null;
		this.onSelect = this.onSelect.bind(this);
		this.alternateRender = this.alternateRender.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.toggleDropdown = this.toggleDropdown.bind(this);
	}

	componentDidMount() {
		let { items, selected } = this.props;

		let item = this.getItem();

		this.update({
			items: items || [],
			key: item.key,
			selected,
			display: item.value || ""
		});

		document.addEventListener("mousedown", this.handleClick, false);
	}

	forceUpdate = () => {
		let { items, selected } = this.props;

		let item = this.getItem();

		this.update({
			items: items || [],
			key: item.key,
			selected,
			display: item.value || ""
		});
	};

	async handleClick(e) {
		if (!this.node) {
			return;
		}

		if (this.node.contains(e.target)) {
			return;
		}

		await this.update({
			show: false
		});

		await this.update({
			classChange: "status-dropdown-display-container"
		});
	}

	getItem() {
		let { items } = this.props;

		let selected = this.props.selected || 0;

		let item = items.find(item => {
			return item.key === selected;
		});

		if (!item) {
			item = items[0];
		}

		if (!item) {
			item = {};
		}

		return item;
	}

	componentDidUpdate(prevProps) {
		let { items, selected } = this.props;

		if (prevProps.items.length !== items.length) {
			let item = this.getItem();
			this.update({
				items,
				key: item.key,
				display: item.value,
				displayTextColor: item.color
			});
		}

		if (prevProps.selected !== selected) {
			let item = this.getItem();
			this.update({
				key: item.key,
				display: item.value,
				displayTextColor: item.color
			});
		}
	}

	toggleDropdown() {
		let { disabled } = this.props;
		let { show } = this.state;

		if (disabled) {
			return;
		}

		this.update({
			show: !show,
			classChange: show ? "status-dropdown-display-container" : "status-dropdown-display-change"
		});
	}

	async onSelect(item) {
		await this.update({
			show: false,
			key: item.key,
			display: item.value,
			displayTextColor: item.color
		});

		await this.update({
			classChange: "status-dropdown-display-container"
		});

		if (this.props.onSelect) {
			this.props.onSelect(item);
		}
	}

	async onStatusSelect(event) {
		let { items } = this.state;
		let { metadata } = this.props;

		await this.update({
			show: false,
			key: event.target.value
		});

		let item = items.find(item => {
			// eslint-disable-next-line eqeqeq
			return item.key == event.target.value;
		});

		if (this.props.onSelect) {
			this.props.onSelect(item, metadata);
		}
	}

	inLineStatusRender() {
		let { show, display, key, items } = this.state;

		return (
			<div ref={node => (this.node = node)} className="in-line-status-dropdown-container">
				{!show && (
					<div onClick={this.toggleDropdown} className="in-line-status-dropdown">
						{display}
					</div>
				)}
				{show && items.length > 0 && (
					<select
						size="2"
						id="inline-select"
						ref={ref => (this.inlineSelect = ref)}
						className="in-line-select"
						onChange={e => this.onStatusSelect(e)}
						value={key}
					>
						{items.map(status => {
							return (
								<option className="in-line-select-option" key={status.key} value={status.key}>
									{status.value}
								</option>
							);
						})}
					</select>
				)}
			</div>
		);
	}

	alternateRender() {
		let { displayTextColor, classChange, display, show, items } = this.state;
		let { className, title } = this.props;

		return (
			<div ref={node => (this.node = node)} onClick={this.toggleDropdown} className="dropdown-alternate-container">
				<span className="dropdown-title">{title}</span>

				<div
					style={{ backgroundColor: displayTextColor, border: displayTextColor && displayTextColor.length > 0 ? "1px solid #E4E6E6" : "" }}
					className={className ? className + " " + classChange : classChange}
				>
					<div>{display}</div>
					<div className="dropdown-chevron">▼</div>
				</div>
				{show && items.length > 0 && (
					<ul className="alternate-dropdown-display">
						{items.map(item => {
							return (
								<li
									style={{ backgroundColor: item.color }}
									className="alternate-dropdown-item"
									key={item.key}
									onClick={() => this.onSelect(item)}
								>
									<span className="alternate-dropdown-item-option">{item.value}</span>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		);
	}

	render() {
		let { alternate, status, inLineStatus } = this.props;
		let { display, show, items } = this.state;

		if (alternate) {
			return this.alternateRender();
		} else if (status) {
			return this.statusRender();
		} else if (inLineStatus) {
			return this.inLineStatusRender();
		}

		return (
			<div ref={node => (this.node = node)} onClick={this.toggleDropdown} className="dropdown-container">
				<div className="dropdown-container-button">
					<div>{display}</div>
					<div className="dropdown-container-button-chevron">▼</div>
				</div>
				{show && items.length > 0 && (
					<div className="dropdown-list">
						{items.map(item => {
							return (
								<div className="dropdown-list-item" key={item.key} onClick={() => this.onSelect(item)}>
									{item.value}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

Dropdown.propTypes = {
	title: PropTypes.string
};

export default Dropdown;
