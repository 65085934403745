// External Libraries
import React from "react";
import * as Icon from "react-feather";
import { withAlert } from "react-alert";
import qs from "query-string";
import _ from "lodash";
import moment from "moment";
import Board from "react-trello";
import { CopyMinus, ExternalLink as IconExternalLink } from "lucide-react";

// Constants
import WORKFLOW from "../../constants/Workflow";
import { STATUSES } from "../../constants/Tasks";

// Services
import UserService from "../../services/UserService";
import EngagementService from "../../services/EngagementService";
import EngagementModal from "./eng_modal/EngagementModal.js";
import TaskService from "../../services/TaskService";
import UtilityService from "../../services/UtilityService.js";

// Components
import AutoGenerateModal from "./auto_generate/AutoGenerateModal";
import EngagementFilterModal from "./EngagmentFilterModal/EngagementFilterModal";
import ViewList from "./ViewList/ViewList";

// Common Components
import UserBubbles from "../common/UserBubbles/UserBubbles";
import WComponent from "../common/WComponent";
import TaskModal from "../tasks/TaskModal";
import Checkbox from "../common/checkbox/Checkbox";
import Loader from "../common/Loader";

// Styling
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import "../common/dropdown/dropdown.css";
import "./engagements.css";

// Specialty Services
import FloatingContainerService from "../../services/FloatingContainerService.js";

let DEFAULT_TITLE = "All Flows";

class Engagements extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			engagements: [],
			partners: [],
			search: "",
			errors: {},
			engagementId: 0,

			flow: {},
			flowFromRoute: {},
			selectedFlowStates: [],

			statuses: Object.values(WORKFLOW.statuses),
			displayTextColor: "",

			title: props.title,
			dashboard: props.dashboard,
			refresh: true,
			showNotesEditor: false,
			engNotes: "",

			newEngagementId: null,
			showAutoGenerateModal: false,

			limit: 50,
			offset: 0,
			loadedAll: false,
			loading: true,

			sort: [{ field: WORKFLOW.orderQueries.fiscalYearEnd, order: "desc" }],

			showFilterModal: false,

			views: [],
			isViewChanged: false,
			showViewModal: false,
			selectedViewId: null,
			savingView: false,
			overwritingView: false,

			minimizeViews: false,

			assignedUsers: [],
			assignedTeams: [],
			assignedClients: [],
			assignedClientGroups: [],
			yearEnd: WORKFLOW.defaultDateFunction,
			dueDate: WORKFLOW.defaultDateFunction,
			dateIn: WORKFLOW.defaultDateFunction,
			columns: null,

			showTaskModal: false,
			selectedTaskId: 0,
			showKanban: false,
			isAllEngagementsExpanded: true,
			showEngagementModal: false,
			isFlowChangedOnViewSelection: false,
			shouldForceDefaultView: false,

			totalEngagementCount: 0,
			totalTaskCount: 0
		};

		this.notesEditor = null;

		this.fetchEngagementsDebounce = _.debounce(this.fetchEngagements, 500, {
			leading: true,
			trailing: true
		});
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = async prevProps => {
		let newProps = this.props.raw ? this.props : this.props.match.params;
		let oldProps = this.props.raw ? prevProps : prevProps.match.params;

		let flowCodeChangedDueToNavlinkChange = oldProps.flowCode !== newProps.flowCode && !this.state.isFlowChangedOnViewSelection;
		if (flowCodeChangedDueToNavlinkChange || oldProps.clientId !== newProps.clientId || oldProps.isMyEngagements !== newProps.isMyEngagements) {
			await this.update({
				shouldForceDefaultView: flowCodeChangedDueToNavlinkChange
			});
			await this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { flowCode, isMyEngagements, clientId, showFilters } = this.props.raw ? this.props : this.props.match.params;

		// XXX - hack
		if (typeof showFilters === "undefined") {
			showFilters = true;
		}

		let params = qs.parseUrl(window.location.href);

		let { assigned } = params.query;

		// eslint-disable-next-line no-use-before-define
		let assignedUserId = assigned ? parseInt(assigned, 10) : assignedUserId;

		let flowFetched = await this.fetchFlowData(flowCode);
		let flowFromRoute = UtilityService.deepCopy(flowFetched);

		let newState = {
			flowFromRoute,
			flow: flowFetched,
			selectedFlowStates: [],
			clientId: clientId || 0,

			limit: 50,
			loading: true,

			assignedUsers: [],
			assignedClients: [],
			assignedClientGroups: [],
			assignedTeams: [],
			yearEnd: WORKFLOW.defaultDateFunction,
			dueDate: WORKFLOW.defaultDateFunction,
			dateIn: WORKFLOW.defaultDateFunction,
			columns: null,

			selectedViewId: null,
			sort: [{ field: WORKFLOW.orderQueries.fiscalYearEnd, order: "desc" }],
			isViewChanged: false,
			isFlowChangedOnViewSelection: false
		};

		if (isMyEngagements) {
			let userData = UserService.getUserData();
			newState.assignedUsers = [
				{
					user_id: userData.userId,
					userName: userData.firstName + " " + userData.lastName
				}
			];
		}

		await this.update(newState);

		await this.fetchEngagements({});
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value
		});
		this.fetchEngagementsDebounce({});
	};

	onLoadMore = async () => {
		let { limit } = this.state;
		await this.update({
			limit: limit + 50
		});

		await this.fetchEngagements({ showLoading: false });

		if (this.state.isAllEngagementsExpanded === false) {
			this.onForceCollapseAll();
		}
	};

	updateEngagement = async ({ engagementId, flowStateId, assignedUsers, notes }) => {
		let { loading } = this.state;
		let { alert } = this.props;

		if (loading) {
			alert.info("Updating...");
			return;
		}

		let updateData = { engagementId };

		if (flowStateId) {
			updateData.flowStateId = flowStateId;
		}

		if (assignedUsers) {
			updateData.assignedUsers = assignedUsers;
		}

		if (notes) {
			updateData.notes = notes;
		}

		let { engagement } = await EngagementService.updateEngagement({ request: updateData });

		updateData.flowState = engagement.flow_state;
		updateData.engagement_users = engagement.engagement_users;

		if (!engagement) {
			alert.success("An error occurred trying to update engagement.");
		} else {
			alert.success("Updated Successfully!");
		}

		this.rerenderEngagement({ engagementId, updateData });
	};

	rerenderEngagement = async ({ engagementId, updateData }) => {
		let { notes, flowStateId, flowState, engagement_users } = updateData;
		let { engagements, refresh } = this.state;

		let copy = engagements.slice();

		copy.forEach(engagement => {
			if (engagement.id === engagementId) {
				if (flowStateId) {
					engagement.flow_state_id = flowStateId;
				}

				if (flowState) {
					engagement._flowStateColor = flowState.color;
					engagement.flow_state = flowState;
				}

				if (notes) {
					engagement.external_notes = notes;
				}

				if (engagement_users) {
					engagement.engagement_users = engagement_users;
				}
			}
		});

		await this.update({
			engagements: copy,
			refresh: !refresh
		});
	};

	fetchFlowData = async flowCode => {
		if (!flowCode) {
			await this.update({
				flow: undefined
			});

			return;
		}

		let flowStates = await EngagementService.fetchFlowStates({ flowCode });

		if (!flowStates) {
			await this.update({
				flow: undefined
			});

			return;
		}

		let flowId = flowStates[0].flow_id;
		let flow = await EngagementService.fetchFlow({ flowId });

		return flow;
	};

	countEngagements = async () => {
		let {
			clientId,
			search,
			limit,
			offset,
			sort,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			selectedFlowStates,
			flow
		} = this.state;

		let flowId = UtilityService.isNotNullish(flow) ? flow.id : undefined;

		let { count } = await EngagementService.fetchEngagements({
			clientId,
			flowId,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			limit,
			offset,
			search,
			sort,
			selectedFlowStates,
			countOnly: true
		});

		this.update({
			totalEngagementCount: count
		});
	};

	fetchEngagements = async ({ showLoading = true }) => {
		let {
			clientId,
			search,
			limit,
			offset,
			sort,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			selectedFlowStates,
			flow
		} = this.state;

		await this.update({
			loading: true && showLoading
		});

		let flowId = UtilityService.isNotNullish(flow) ? flow.id : undefined;

		let engagements = await EngagementService.fetchEngagements({
			clientId,
			flowId,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			limit,
			offset,
			search,
			sort,
			selectedFlowStates
		});

		if (!engagements) {
			await this.update({
				loading: false && showLoading
			});

			return;
		}

		await this.update({
			loading: false && showLoading,
			loadedAll: engagements.length < limit,
			engagements
		});

		await this.countEngagements();
	};

	sortBy = async ({ field }) => {
		let { sort } = this.state;

		let previousSort = sort;

		let index = -1;
		for (let i = 0; i < sort.length; i++) {
			if (sort[i].field === field) {
				index = i;
				break;
			}
		}

		if (index === -1) {
			sort.push({ field, order: "desc" });
		} else {
			let sortField = sort[index];

			sort.splice(index, 1);
			if (sortField.order === "desc") {
				sortField.order = "asc";

				sort.push(sortField);
			}
		}

		let isViewChanged = false;

		if (JSON.stringify(previousSort) === JSON.stringify(sort)) {
			isViewChanged = true;
		}

		await this.update({
			sort,
			isViewChanged
		});

		await this.fetchEngagements({ showLoading: false });
	};

	renderSortIcon = ({ field }) => {
		let { sort } = this.state;

		let index = -1;
		for (let i = 0; i < sort.length; i++) {
			if (sort[i].field === field) {
				index = i;
				break;
			}
		}

		if (index === -1) {
			return null;
		}

		return sort[index].order === "desc" ? (
			<div style={{ fontSize: 10 }}>
				<Icon.ChevronUp size={10} />
				{index + 1}
			</div>
		) : (
			<div style={{ fontSize: 10 }}>
				<Icon.ChevronDown size={10} />
				{index + 1}
			</div>
		);
	};

	openEngagementModal = () => {
		this.update({ showEngagementModal: true });
	};

	onEngagementModalClose = () => {
		this.update({ showEngagementModal: false });
	};

	onCreate = async engagementId => {
		await this.update({
			loading: true
		});

		if (engagementId) {
			await this.update({
				showEngagementModal: false,
				showAutoGenerateModal: true,
				newEngagementId: engagementId
			});
		}

		await this.fetchEngagements({});

		await this.update({
			loading: false
		});
	};

	onEnter = (e, engagementId) => {
		if (e.keyCode === 13) {
			this.onSaveEngNotes(engagementId);
		} else if (e.keyCode === 27) {
			this.update({ showNotesEditor: false, engNotes: "" });
		}
	};

	async onSaveEngNotes(engagementId) {
		let { engNotes } = this.state;

		await this.update({
			showNotesEditor: false
		});

		await this.updateEngagement({ engagementId, notes: engNotes });
	}

	onExport = async () => {
		let {
			clientId,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			search,
			limit,
			offset,
			sort,
			selectedFlowStates,
			flow
		} = this.state;

		let { alert } = this.props;

		let exportingAlert = alert.info("Export current data ...", {
			timeout: 0
		});

		const flowId = UtilityService.isNotNullish(flow) ? flow.id : undefined;

		await EngagementService.exportEngagements({
			clientId,
			flowId,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			limit,
			offset,
			search,
			sort,
			selectedFlowStates
		});

		alert.remove(exportingAlert);
		alert.success("Export Complete.", { timeout: 3000 });
	};

	onEngagementFilterModalClose = async () => {
		await this.update({
			showFilterModal: false
		});
	};

	onShowFilterModal = async () => {
		await this.update({
			showFilterModal: true
		});
	};

	onFilterChange = async ({ filters, columns }) => {
		let { assignedUsers, assignedClients, assignedClientGroups, assignedTeams, yearEnd, dueDate, dateIn, flow, selectedFlowStates } = filters;

		await this.update({
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			columns,
			isViewChanged: true,
			flow,
			selectedFlowStates
		});

		await this.fetchEngagements({ showLoading: true });
	};

	onDefaultViewSelected = async () => {
		await this.resetComponent();
	};

	onViewSelected = async ({ view }) => {
		let { flow } = this.state;
		let { alert } = this.props;

		let { filters, sort, columns } = JSON.parse(view.schema);

		let { assignedUsers, assignedClients, assignedClientGroups, assignedTeams, yearEnd, dueDate, dateIn, flow: selectedFlow, selectedFlowStates } = filters;

		if (!assignedTeams) {
			assignedTeams = [];
		}

		if (!assignedClients) {
			assignedClients = [];
		}

		if (!assignedClientGroups) {
			assignedClientGroups = [];
		}

		if (!yearEnd) {
			yearEnd = WORKFLOW.defaultDateFunction;
		}

		if (!dueDate) {
			dueDate = WORKFLOW.defaultDateFunction;
		}

		if (!dateIn) {
			dateIn = WORKFLOW.defaultDateFunction;
		}

		let newFlow;
		if (selectedFlow) {
			newFlow = await EngagementService.fetchFlow({ flowId: selectedFlow.flowId });
		}

		await this.update({
			flow: newFlow,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			sort,
			columns,
			selectedFlowStates,
			isViewChanged: false,
			isFlowChangedOnViewSelection: true,
			shouldForceDefaultView: false
		});

		if (selectedFlow) {
			this.props.history.push(`/workflow/${newFlow.code}`);
		} else {
			this.props.history.push(`/workflow`);
		}

		if (!flow || !newFlow || newFlow.id !== flow.id) {
			alert.info("Engagement Type changed due to newly selected view.");
		}

		await this.fetchEngagements({});

		await this.update({
			isFlowChangedOnViewSelection: false
		});
	};

	shouldDisplayClient()
	{
		let { columns } = this.state;
		
		if(_.isEmpty(columns))
		{
			return false;
		}

		return true;
	}

	viewHasColumn(columnId) {
		let { columns } = this.state;

		if (_.isEmpty(columns)) {
			return true;
		}

		return columns[columnId];
	}

	safelyDeriveFlowCode = async ({ engagement }) => {
		let flowId = engagement.flow_id;

		try {
			return engagement.flow.code;
		} catch (error) {
			console.log(`Flow Code does not exist on engagement ${engagement.id} - ${error} - fetching manually from backend...`);
			let flow = await EngagementService.fetchFlow({ flowId });
			return flow.code;
		}
	};

	onFlowStateChange = async ({ targetElement, engagement }) => {
		let flowCode = await this.safelyDeriveFlowCode({ engagement });

		FloatingContainerService.openFloatingContainer({
			name: "statusSelector",
			positionBeside: targetElement,

			// Callback fired right before showing the floating component, helpful for add more customization to the behaviours
			beforeShow: component => {
				component.setSelectCallback(item => {
					this.updateEngagement({ engagementId: engagement.id, flowStateId: item.id });
				});

				component.setFlowCode(flowCode);
			}
		});
	};

	onTaskSelect = async ({ task }) => {
		await this.update({
			selectedTaskId: task.id,
			showTaskModal: true
		});
	};

	rerenderTask = async ({ task }) => {
		let { engagements } = this.state;

		let newEngagements = engagements.slice();

		for (let newEngagement of newEngagements) {
			for (let t of newEngagement.tasks) {
				if (t.id === task.id) {
					t.name = task.name;
					t.status = task.status;
				}
			}
		}

		await this.update({
			engagements: newEngagements
		});
	};

	onTaskModalClose = async ({ task }) => {
		if (task) {
			await this.rerenderTask({ task });
		}

		await this.update({
			selectedTaskId: 0,
			showTaskModal: false
		});
	};

	onRecordClick = ({ engagement }) => {
		this.props.history.push(`/workflow/${engagement.flow.code}/${engagement.id}`);
	};

	onNewTabClick = ({ engagement }) => {
		const route = `/workflow/${engagement.flow.code}/${engagement.id}`;
		const newTab = window.open(route, "_blank");
		newTab.focus();
	};

	onToggleExpandRecord = async ({ event, engagement }) => {
		event.stopPropagation();

		let { engagements } = this.state;

		let newEngagements = engagements.slice();

		for (let newEngagement of newEngagements) {
			if (newEngagement.id === engagement.id) {
				newEngagement.collapsed = !newEngagement.collapsed;
			}
		}

		await this.update({
			engagements: newEngagements
		});
	};

	onToggleExpandAll = async () => {
		let { engagements, isAllEngagementsExpanded } = this.state;

		let newEngagements = engagements.slice();

		for (let newEngagement of newEngagements) {
			newEngagement.collapsed = isAllEngagementsExpanded;
		}

		await this.update({
			engagements: newEngagements,
			isAllEngagementsExpanded: !isAllEngagementsExpanded
		});
	};

	onForceCollapseAll = async () => {
		let { engagements } = this.state;

		let newEngagements = engagements.slice();

		for (let newEngagement of newEngagements) {
			newEngagement.collapsed = true;
		}

		await this.update({
			engagements: newEngagements,
			isAllEngagementsExpanded: false
		});
	};

	onTaskStatusUpdate = async ({ event, task }) => {
		let { alert } = this.props;

		event.stopPropagation();

		let updatedTask = null;

		try {
			let newStatus = null;

			if (task.status !== STATUSES.done.id) {
				newStatus = STATUSES.done.id;
			} else {
				newStatus = STATUSES.in_progress.id;
			}

			updatedTask = await TaskService.update({ taskId: task.id, status: newStatus });

			alert.success("Updated Successfully!");
		} catch (error) {
			console.log(error);
			alert.error("Updated error ...");
		}

		await this.rerenderTask({ task: updatedTask });
	};

	onUserBubblesClicked = ({ targetElement, engagement }) => {
		let assignedUsersFromState = engagement.engagement_users.map(eu => {
			return {
				user_id: eu.user_id,
				role_id: eu.role_id,

				userName: `${eu.User.first_name} ${eu.User.last_name}`,
				roleName: `${eu.Role.name}`
			};
		});

		FloatingContainerService.openFloatingContainer({
			name: "userAssigner",
			positionBeside: targetElement,

			beforeShow: component => {
				component.setEngagementName(engagement.name);
				component.setAssignedUsers(assignedUsersFromState);

				component.setAssignedUsersChangedCallback(assignedUsers => {
					this.updateEngagement({ engagementId: engagement.id, assignedUsers });
				});
			}
		});
	};

	onNotesClicked = ({ targetElement, engagement }) => {
		FloatingContainerService.openFloatingContainer({
			name: "notes",
			positionBeside: targetElement,

			beforeShow: component => {
				component.setEngagementName(engagement.name);
				component.setEngagementId(engagement.id);
				component.setClientId(engagement.client_id);

				component.setOnChangeCallback(notes => {
					this.rerenderEngagement({
						engagementId: engagement.id,
						updateData: { notes }
					});
				});
			}
		});
	};

	renderTaskProgress = ({ engagement }) => {
		let total = engagement.tasks.length;

		if (total === 0) {
			return <div className="list-column"></div>;
		}

		let completed = engagement.tasks.filter(task => {
			return task.status === "done";
		}).length;

		let percentage = (completed / total) * 100;

		return (
			<div className="list-column">
				<div className="task-progress">
					<div className="task-progress__ratio">
						{completed} / {total}
					</div>
					<div className="task-progress__bar">
						<div className="task-progress__bar__completed" style={{ width: `${percentage}%` }}></div>
					</div>
				</div>
			</div>
		);
	};

	EngagementCard = props => {
		let { id, engagement } = props;
		return <div>{engagement.name}</div>;
	};

	renderKanban() {
		let { engagements, flow } = this.state;

		let flowStates = UtilityService.isNotNullish(flow) ? flow.flowStates : [];

		let lanes = flowStates.map(flowState => {
			return {
				id: flowState.id,
				title: flowState.name,
				cards: [],
				style: {
					backgroundColor: flowState.color
				}
			};
		});

		for (let engagement of engagements) {
			for (let lane of lanes) {
				if (engagement.flow_state_id === lane.id) {
					lane.cards.push({
						id: engagement.id,
						engagement
					});
				}
			}
		}

		const data = {
			lanes
		};

		return <Board className="kb-container" style={{ backgroundColor: "#eaedf3" }} data={data} components={{ Card: this.EngagementCard }} />;
	}

	getFilterSchema = () => {
		let {
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			sort,
			columns,
			selectedFlowStates,
			flow,
			totalEngagementCount
		} = this.state;

		let schema = {
			filters: {
				flow,
				selectedFlowStates,
				assignedUsers,
				assignedClients,
				assignedClientGroups,
				assignedTeams,
				yearEnd,
				dueDate,
				dateIn
			},
			columns,
			sort
		};

		return schema;
	};

	render() {
		let {
			flow,
			flowFromRoute,
			selectedFlowStates,
			showFilterModal,
			showNotesEditor,
			engNotes,
			loading,
			search,
			loadedAll,
			engagements,
			showAutoGenerateModal,
			newEngagementId,
			clientId,

			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			columns,

			showTaskModal,
			selectedTaskId,

			showKanban,

			showEngagementModal,

			isViewChanged,

			totalEngagementCount
		} = this.state;
		let { title } = this.props;

		if (!title) {
			title = DEFAULT_TITLE;
		}

		let flowCode = UtilityService.isNotNullish(flow) ? flow.code : "";

		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Workflow</h3>
						<h1 className="container-header-title__name">{flow && flow.name ? flow.name : title}</h1>
					</div>

					<div className="container-header-options">
						{totalEngagementCount > 0 && <div>Total Engagements: {totalEngagementCount}</div>}
						<button className="container-add" onClick={this.onToggleExpandAll} type="button">
							<CopyMinus size={18} />
						</button>
						<button className="container-add" onClick={this.onShowFilterModal} type="button">
							<Icon.Sliders size={18} />
						</button>
						<button className="container-add" onClick={this.openEngagementModal} type="button">
							<Icon.Plus size={18} />
						</button>
						<button className="container-add" onClick={this.onExport} type="button">
							<Icon.DownloadCloud size={18} />
						</button>
						<input
							className="container-search"
							type="search"
							name="search"
							placeholder="Search..."
							value={search}
							autoComplete="off"
							onChange={this.onSearch}
						/>
					</div>
				</div>

				<div className="test-container">
					<ViewList
						type={"engagement"}
						isViewChanged={isViewChanged}
						getSchema={this.getFilterSchema}
						onViewSelected={this.onViewSelected}
						onDefaultViewSelected={this.onDefaultViewSelected}
						shouldForceDefaultView={this.state.shouldForceDefaultView}
					/>

					{loading && (
						<div className="container-loader">
							<Loader />
						</div>
					)}

					{engagements.length === 0 && !loading && (
						<div className="container-empty">
							<div>
								<Icon.Frown size={128} />
							</div>
							<div>... no engagements ...</div>
						</div>
					)}

					{engagements.length > 0 && !loading && (
						<div className="scroll-container">
							{showKanban && this.renderKanban()}
							{!showKanban && (
								<div className="list-container scroll-container__list">
									<div className="list-header list-item">
										{this.viewHasColumn(WORKFLOW.columns.name.id) && (
											<div onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.name })} className="list-column list-column--xxl">
												Name {this.renderSortIcon({ field: WORKFLOW.orderQueries.name })}
											</div>
										)}
										{this.viewHasColumn(WORKFLOW.columns.name.id) && (
											<div className="list-column list-column--xxs">{/** Purposefully left empty, it's column for new tab */}</div>
										)}
										{this.viewHasColumn(WORKFLOW.columns.client.id) && this.shouldDisplayClient() && (
											<div onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.clientName })} className="list-column list-column--xs">
												Client {this.renderSortIcon({ field: WORKFLOW.orderQueries.clientName })}
											</div>
										)}

										{this.viewHasColumn(WORKFLOW.columns.type.id) && (
											<div onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.type })} className="list-column list-column--xs">
												Type {this.renderSortIcon({ field: WORKFLOW.orderQueries.type })}
											</div>
										)}

										{this.viewHasColumn(WORKFLOW.columns.team.id) && (
											<div onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.team })} className="list-column list-column--xs">
												Team {this.renderSortIcon({ field: WORKFLOW.orderQueries.team })}
											</div>
										)}

										{this.viewHasColumn(WORKFLOW.columns.users.id) && <div className="list-column list-column--l">Users</div>}

										{this.viewHasColumn(WORKFLOW.columns.status.id) && (
											<div onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.status })} className="list-column list-column--m">
												Status {this.renderSortIcon({ field: WORKFLOW.orderQueries.status })}
											</div>
										)}

										{this.viewHasColumn(WORKFLOW.columns.lastNote.id) && (
											<div onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.notes })} className="list-column list-column--xl">
												Last Note {this.renderSortIcon({ field: WORKFLOW.orderQueries.notes })}
											</div>
										)}

										{this.viewHasColumn(WORKFLOW.columns.taskProgress.id) && <div className="list-column">Task Progress</div>}

										{this.viewHasColumn(WORKFLOW.columns.dateIn.id) && (
											<div onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.dateIn })} className="list-column list-column--xs">
												Date In {this.renderSortIcon({ field: WORKFLOW.orderQueries.dateIn })}
											</div>
										)}

										{this.viewHasColumn(WORKFLOW.columns.dueDate.id) && (
											<div onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.dueDate })} className="list-column list-column--xs">
												Due Date {this.renderSortIcon({ field: WORKFLOW.orderQueries.dueDate })}
											</div>
										)}

										{this.viewHasColumn(WORKFLOW.columns.fiscalYearEnd.id) && (
											<div
												onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.fiscalYearEnd })}
												className="list-column list-column--xs"
											>
												Fiscal Year End {this.renderSortIcon({ field: WORKFLOW.orderQueries.fiscalYearEnd })}
											</div>
										)}
									</div>

									{engagements.map((eng, index) => {
										let flowStates = eng.flow.flow_states;

										// TODO: Should be done on the backend
										flowStates = flowStates.sort((fs1, fs2) => {
											if (fs1.order < fs2.order) {
												return -1;
											}

											if (fs1.order > fs2.order) {
												return 1;
											}

											return 0;
										});

										for (let fs of flowStates) {
											fs.key = fs.id;
											fs.value = fs.name;
										}

										let hasAnyNotes = eng.external_notes.length > 0;
										let lastNote = eng.external_notes[eng.external_notes.length - 1];

										let note = hasAnyNotes ? lastNote.content : "";

										// XXX - Super gross
										if (!eng._flowStateColor) {
											eng._flowStateColor = eng.flow_state ? eng.flow_state.color : "white";
										}

										if (typeof eng.collapsed === "undefined") {
											eng.collapsed = eng.tasks.length === 0;
										}

										const showTasks = !eng.collapsed;
										let statusColumnRef = null;
										let userBubblesColumnRef = null;
										let notesColumnRef = null;

										let alreadyRenderedDivider = false;

										return (
											<React.Fragment key={`${index}-${eng.id}`}>
												<div className="list-item" style={{ backgroundColor: eng._flowStateColor }}>
													{this.viewHasColumn(WORKFLOW.columns.name.id) && (
														<div
															className="list-column list-column--xxl"
															onClick={() => {
																this.onRecordClick({ engagement: eng });
															}}
														>
															<span
																className="collapse-engagement"
																onClick={event => this.onToggleExpandRecord({ event, engagement: eng })}
															>
																{eng.collapsed ? <Icon.ChevronRight size={16} /> : <Icon.ChevronDown size={16} />}
															</span>

															{eng.name}
														</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.name.id) && (
														<div className="list-column list-column--xxs">
															<IconExternalLink size={18} onClick={() => this.onNewTabClick({ engagement: eng })} />
														</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.client.id) && this.shouldDisplayClient() && (
														<div className="list-column list-column--xs">{eng.Client.name}</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.type.id) && (
														<div className="list-column list-column--xs">{eng.flow.name}</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.team.id) && (
														<div className="list-column list-column--xs">{eng.Team ? eng.Team.name : "Unassigned"}</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.users.id) && (
														<div
															ref={ref => (userBubblesColumnRef = ref)}
															id="user-column"
															className="list-column list-column--l"
															onClick={() => this.onUserBubblesClicked({ targetElement: userBubblesColumnRef, engagement: eng })}
														>
															<UserBubbles engagementUsers={eng.engagement_users} />
														</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.status.id) && (
														<div
															ref={ref => (statusColumnRef = ref)}
															className="list-column list-column--m"
															onClick={() => this.onFlowStateChange({ engagement: eng, targetElement: statusColumnRef })}
														>
															{eng.flow_state.name}

															{/* <Dropdown
																items={flowStates}
																selected={eng.flow_state_id}
																onSelect={item => this.updateEngagement({ engagementId: eng.id, flowStateId: item.key })}
																inLineStatus={true}
															/> */}
														</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.lastNote.id) && showNotesEditor !== eng.id && (
														<div
															ref={ref => (notesColumnRef = ref)}
															className="list-column list-column--xl"
															onClick={() => this.onNotesClicked({ engagement: eng, targetElement: notesColumnRef })}
														>
															{hasAnyNotes && <>{note}&nbsp;</>}
															{!hasAnyNotes && <div className="btn btn--mini">Add a note</div>}
														</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.lastNote.id) && showNotesEditor === eng.id && (
														<div className="list-column list-column--xl">
															<div className="workflow-notes-editor">
																<input
																	ref={ref => (this.notesEditor = ref)}
																	className="workflow-notes-editor-input"
																	placeholder="Enter some notes ..."
																	value={engNotes}
																	onKeyDown={event => this.onEnter(event, eng.id)}
																	onChange={event => this.setState({ engNotes: event.target.value })}
																/>
																<div className="workflow-notes-editor__button" onClick={() => this.onSaveEngNotes(eng.id)}>
																	<Icon.Check size={16} />
																</div>
																<div
																	className="workflow-notes-editor__button"
																	onClick={() => this.setState({ showNotesEditor: false, engNotes: "" })}
																>
																	<Icon.X size={16} />
																</div>
															</div>
														</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.taskProgress.id) && this.renderTaskProgress({ engagement: eng })}

													{this.viewHasColumn(WORKFLOW.columns.dateIn.id) && (
														<div className="list-column list-column--xs">{moment(eng.date_in).format("YYYY-MM-DD")}</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.dueDate.id) && (
														<div className="list-column list-column--xs">{moment(eng.due_date).format("YYYY-MM-DD")}</div>
													)}

													{this.viewHasColumn(WORKFLOW.columns.fiscalYearEnd.id) && (
														<div className="list-column list-column--xs">
															{flowCode === "t1" ? eng.fiscal_year_end.substring(0, 4) : eng.fiscal_year_end}
														</div>
													)}
												</div>

												{showTasks &&
													eng.tasks.map((task, index) => {
														let isLastTask = index === eng.tasks.length - 1;
														alreadyRenderedDivider = isLastTask;
														return (
															<React.Fragment key={index}>
																<div
																	className="list-item"
																	style={{ backgroundColor: STATUSES[task.status].color }}
																	onClick={() => this.onTaskSelect({ task })}
																>
																	<div className="list-column list-column--xxs" style={{ maxWidth: 40 }}>
																		<span className="engagement-task">
																			<Icon.CornerDownRight size={16} />
																		</span>
																	</div>
																	<div className="list-column list-column--xxs" style={{ maxWidth: 20 }}>
																		<Checkbox
																			name="selectAll"
																			checked={task.status === STATUSES.done.id}
																			onChange={({ event }) => this.onTaskStatusUpdate({ event, task })}
																			isSmall
																		/>
																	</div>
																	<div className="list-column list-column--xl">{task.name}</div>
																</div>
																{isLastTask && <hr className="engagement-divider" />}
															</React.Fragment>
														);
													})}

												{!alreadyRenderedDivider && <hr className="engagement-divider" />}
											</React.Fragment>
										);
									})}
									{!loadedAll && (
										<div className="list-load-more">
											<div className="btn btn--sm" onClick={this.onLoadMore}>
												Load More
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</div>

				<EngagementModal
					show={showEngagementModal}
					flowId={UtilityService.isNotNullish(flow) ? flow.id : 0}
					clientId={clientId}
					onCreate={this.onCreate}
					onClose={this.onEngagementModalClose}
					{...this.props}
				/>
				<AutoGenerateModal show={showAutoGenerateModal} engagementId={newEngagementId} {...this.props} />
				<EngagementFilterModal
					show={showFilterModal}
					onClose={this.onEngagementFilterModalClose}
					onFilterChange={this.onFilterChange}
					flowFromRoute={flowFromRoute}
					filters={{
						assignedUsers,
						assignedClients,
						assignedClientGroups,
						assignedTeams,
						yearEnd,
						dueDate,
						dateIn,
						columns,
						selectedFlowStates,
						flow
					}}
				/>

				<TaskModal taskId={selectedTaskId} show={showTaskModal} onClose={this.onTaskModalClose} {...this.props} />
			</div>
		);
	}
}

export default withAlert(Engagements);
