import React from "react";
import WComponent from "../common/WComponent";

// import { HotTable } from '@handsontable/react';
// import { registerAllModules } from "handsontable/registry";
// import "handsontable/dist/handsontable.full.min.css";

export default class ClientsBeta extends WComponent {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	componentDidMount = () => {};

	render() {
		return (
			<div className="container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Clients</h3>
						{/* <h1 className="container-header-title__name">{CLIENTS.clientTypes[type].value}</h1> */}
					</div>
				</div>
			</div>
		);
	}
}
