import React from "react";
import { withAlert } from "react-alert";
import UserService from "../../services/UserService";
import TextFieldInput from "../common/text_field_input/TextFieldInput";
import * as Icon from "react-feather";
import UtilityService from "../../services/UtilityService";

import "./settings.css";
import StaffService from "../../services/StaffService";
import WComponent from "../common/WComponent";
import SearchableDropdown from "../common/searchable_dropdown/SearchableDropdown";

class StaffSettings extends WComponent {
	constructor() {
		super();

		this.state = {
			loading: true,

			firstName: "",
			lastName: "",
			title: "",
			email: "",
			phone: "",
			password: "",
			confirmPassword: "",
			status: "",
			defaultRoleId: 0,
			defaultRoleName: ""
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {
		let { userId } = this.props.match.params;

		if (prevProps.match.params.userId !== userId) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { userId } = this.props.match.params;

		await this.update({
			userId: userId
		});

		await this.update({
			loading: true
		});

		if (userId === "new") {
			await this.update({
				loading: false
			});
			return;
		}

		await this.fetchUser();
	};

	fetchUser = async () => {
		let { userId } = this.state;

		let user = await UserService.fetchUser({ userId });

		await this.update({
			loading: false
		});

		if (!user) {
			// Call react-alert -> user does not exist
			this.props.alert.error("User does not exist.");
			this.props.history.push("/settings/company");
			return;
		}

		await this.update({
			firstName: user.first_name,
			lastName: user.last_name,
			title: user.title,
			email: user.email,
			phone: user.phone,
			status: user.status,
			defaultRoleId: UtilityService.isNullish(user.defaultRole) ? undefined : user.defaultRole.id,
			defaultRoleName: UtilityService.isNullish(user.defaultRole) ? undefined : user.defaultRole.name
		});
	};

	updateUser = async () => {
		let { alert } = this.props;
		let { userId, firstName, lastName, title, email, phone, status, password, defaultRoleId } = this.state;

		if (!this.isValid()) {
			// Call react-alert
			alert.error("Missing fields!");
			return;
		}

		if (this.state.loading) {
			// Call react-alert
			alert.info("Updating...");
			return;
		}

		try {
			await StaffService.update({
				userId: userId,
				firstName: firstName,
				lastName: lastName,
				password: password,
				title: title,
				email: email,
				phone: phone,
				status: status,
				defaultRoleId: defaultRoleId
			});

			// Call react-alert
			alert.success("Updated Successfully!");
		} catch (error) {
			alert.error("Update Error!");
			console.log(error);
		}

		await this.update({
			loading: false
		});
	};

	createUser = async () => {
		let { alert, history } = this.props;
		let { firstName, lastName, title, email, phone, password, defaultRoleId } = this.state;

		if (!this.isValid()) {
			// Call react-alert saying there are missing fields
			alert.error("Missing fields!");
			return;
		}

		if (this.state.loading) {
			// Call react-alert saying the client is being updated
			alert.info("Updating...");
			return;
		}

		this.update({
			loading: true
		});

		try {
			let response = await StaffService.create({ firstName, lastName, title, email, phone, password, defaultRoleId });

			// Call react-alert saying that the client was updated successfully
			alert.success("Created Successfully!");
			history.push(`/settings/users/${response.data.id}`);
		} catch (error) {
			console.log(error);
			// Call react-alert saying there is some kind of error trying to update the client
			alert.error("Update Error!");
		}

		this.update({
			loading: false
		});
	};

	archiveUser = async () => {
		let { loading, status, userId } = this.state;
		let { alert } = this.props;

		if (!this.isValid()) {
			// Call react-alert
			alert.error("Missing fields!");
			return;
		}

		if (loading) {
			// Call react-alert
			alert.info("Updating...");
			return;
		}

		let newStatus = status === "active" ? "inactive" : "active";

		try {
			await StaffService.update({ userId, status: newStatus });

			// Call react-alert
			alert.success("Updated Successfully!");

			await this.update({
				status: newStatus
			});
		} catch (error) {
			alert.error("Update Error!");
			console.log(error);
		}

		this.update({
			loading: false
		});
	};

	onChange = e => {
		let { name, value } = e.target;

		this.update({
			[name]: value
		});
	};

	onTitleSelect = title => {
		this.update({
			title: title.key
		});
	};

	onSelectDefaultRole = role => {
		this.update({
			defaultRoleId: role.id,
			defaultRoleName: role.name
		});
	};

	onBack = () => {
		let { history } = this.props;
		history.goBack();
	};

	isNewUser = () => {
		let { userId } = this.state;
		return userId === "new";
	};

	isDisabled = () => {
		let { loading } = this.state;
		return !this.isValid() || loading;
	};

	isPasswordCorrectLength = () => {
		let { password } = this.state;

		return password.length >= 8;
	};

	isPasswordMatching = () => {
		let { password, confirmPassword } = this.state;
		return password === confirmPassword && password.length >= 8;
	};

	isValid = () => {
		let { firstName, lastName, email, password, confirmPassword } = this.state;

		let isPasswordValid =
			this.isNewUser() || password.length > 0 || confirmPassword.length > 0 ? password === confirmPassword && password.length >= 8 : true;

		if (!firstName || !lastName || !email) {
			return;
		}

		return firstName.length > 0 && lastName.length > 0 && email.length > 0 && isPasswordValid;
	};

	render() {
		let { firstName, lastName, title, email, phone, password, confirmPassword, status, defaultRoleName } = this.state;

		return (
			<div className="user-details-container">
				<div className="container-header">
					<div className="container-header-title">
						<h3 className="container-header-title__sub">Staff</h3>
						<div className="container-header-title__name">
							<div className="container-header-title__name__back" onClick={this.onBack}>
								<Icon.ArrowLeftCircle size={32} />
							</div>
							{this.isNewUser() ? `New User` : `${firstName} ${lastName} ${title ? " - " + title : ""}`}
						</div>
					</div>
				</div>

				<div className="user-details-form-container">
					<div className="user-details-form">
						<div>
							<TextFieldInput title="First Name" name="firstName" value={firstName || ""} onChange={this.onChange} autoComplete="off" />

							<TextFieldInput title="Last Name" name="lastName" value={lastName || ""} onChange={this.onChange} autoComplete="off" />

							<TextFieldInput title="Title" name="title" value={title || ""} onChange={this.onChange} autoComplete="off" />

							<SearchableDropdown
								title="Default Role"
								type={"roles"}
								selectedName={defaultRoleName || ""}
								onSelect={this.onSelectDefaultRole}
								autoComplete="off"
							/>

							{/*<div className="contact-section">
                                <div>
                                    <h5>Preferred Contact Medium</h5>
                                </div>

                                <div className="contact-buttons">
                                    <Link to="#" style={{ textDecoration: 'none'}}>
                                        <input type="submit" value="Email" id="email-button"/>
                                    </Link>
                                    <Link to="#" style={{ textDecoration: 'none' }}>
                                        <input type="submit" value="SMS" id="sms-button"/>
                                    </Link>
                                </div>
                            </div>*/}
						</div>
						<div className="staff-settings-container-2">
							<TextFieldInput
								disabled={!UserService.isAdministrator()}
								title="Email"
								name="email"
								value={email || ""}
								onChange={this.onChange}
								autoComplete="off"
							/>

							<TextFieldInput title="Phone" name="phone" value={phone || ""} onChange={this.onChange} autoComplete="off" />

							<TextFieldInput title="Password" type="password" name="password" value={password} onChange={this.onChange} autoComplete="off" />

							<TextFieldInput
								title="Confirm Password"
								type="password"
								name="confirmPassword"
								value={confirmPassword}
								onChange={this.onChange}
								autoComplete="off"
							/>

							<div className="user-password">{this.isPasswordMatching() ? "Password Matches" : ""}</div>
						</div>
					</div>

					<div className="btn-group">
						<div className={"btn" + (this.isDisabled() ? "disable" : "")} onClick={this.isNewUser() ? this.createUser : this.updateUser}>
							{this.isNewUser() ? "Create" : "Update"}
						</div>
						{!this.isNewUser() && (
							<div className="btn" onClick={this.archiveUser}>
								{status === "active" ? "Archive" : "Unarchive"}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default withAlert(StaffSettings);
